import React from "react";
import PropTypes from "prop-types";
import Fab from "@material-ui/core/Fab";
import SvgIcon from "@material-ui/core/SvgIcon";
import { ReactComponent as ShoppingCartIcon } from "../../static/shopping_cart_icon.svg";
import { useStyles } from "./AkularStoreFAB.styles";
import useFirebaseApp from "../../modules/firebaseApp";
import useGlobalClasses from "../../hooks/useGlobalClasses";
import clsx from "clsx";

const AkularStoreFAB = ({ chatbotVisible = true }) => {
  const classes = useStyles();
  const globalClasses = useGlobalClasses();
  const fbApp = useFirebaseApp();

  if (!fbApp.ui("akularStoreFab", ["R"])) return null;

  return (
    <a href="https://store.akular.com/" target="_blank" rel="noopener noreferrer" className={classes.fabContainer}>
      <Fab
        classes={{ root: classes.storeFab }}
        className={clsx(globalClasses.akularBlue, classes.floatStore, {
          [classes.floatStoreLeftShift]: chatbotVisible,
        })}>
        <SvgIcon fontSize="large">
          <ShoppingCartIcon />
        </SvgIcon>
      </Fab>
    </a>
  );
};

AkularStoreFAB.propTypes = {
  chatbotVisible: PropTypes.bool.isRequired,
};

export default AkularStoreFAB;
