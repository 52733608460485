import { useFirebaseApp } from "../../modules/firebaseApp";
import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import Hidden from "@material-ui/core/Hidden";
import AkularLogo from "../../static/akular.svg";
import AkularLogoMobile from "../../static/akular_logo_mobile.png";
import { useStyles } from "./Logo.styles";
import PropTypes from "prop-types";
import clsx from "clsx";

const Logo = ({ forceDesktop }) => {
  const classes = useStyles();
  const fbApp = useFirebaseApp();
  const [initialized, setInitialized] = useState(false);
  const [mobileUrl, setMobileUrl] = useState(null);
  const [desktopUrl, setDesktopUrl] = useState(null);

  const hasCustomLogos = (orgConfig) =>
    !!(orgConfig?.customLogo?.mobile?.fullPath && orgConfig?.customLogo?.desktop?.fullPath);

  useEffect(() => {
    (async function () {
      if (fbApp.isLoaded && !initialized) {
        if (hasCustomLogos(fbApp.orgConfig.value)) {
          const mUrl = await fbApp.st.ref(fbApp?.orgConfig?.value?.customLogo?.mobile?.fullPath).getDownloadURL();
          const dUrl = await fbApp.st.ref(fbApp?.orgConfig?.value?.customLogo?.desktop?.fullPath).getDownloadURL();

          setMobileUrl(mUrl);
          setDesktopUrl(dUrl);
        } else {
          console.log("No custom logo metadata");
        }
        setInitialized(true);
      }
    })();
  }, [fbApp, initialized]);

  if (!fbApp.isLoaded || !initialized) return null;

  if (mobileUrl && desktopUrl) {
    return (
      <Box display="flex" alignContent="center" height="100%">
        {forceDesktop ? (
          <img alt="Akular" className={clsx(classes.logoDesktop, classes.userSelectNone)} src={desktopUrl} />
        ) : (
          <>
            <Hidden smDown>
              <img alt="Akular" className={clsx(classes.logoDesktop, classes.userSelectNone)} src={desktopUrl} />
            </Hidden>
            <Hidden mdUp>
              <img alt="Akular" className={clsx(classes.logoMobile, classes.userSelectNone)} src={mobileUrl} />
            </Hidden>
          </>
        )}
      </Box>
    );
  }

  return (
    <Box display="flex" alignContent="center" height="100%">
      {forceDesktop ? (
        <img alt="Akular" className={clsx(classes.fullWidth, classes.userSelectNone)} src={AkularLogo} />
      ) : (
        <>
          <Hidden smDown>
            <img alt="Akular" className={clsx(classes.fullWidth, classes.userSelectNone)} src={AkularLogo} />
          </Hidden>
          <Hidden mdUp>
            <img alt="Akular" className={clsx(classes.logoMobile, classes.userSelectNone)} src={AkularLogoMobile} />
          </Hidden>
        </>
      )}
    </Box>
  );
};

Logo.propTypes = {
  forceDesktop: PropTypes.bool,
};

Logo.defaultProps = {
  forceDesktop: false,
};

export default Logo;
