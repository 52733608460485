import { Loadable } from "utils/components";
import LoadingSpinner from "components/LoadingSpinner";
import { SUPPORT_PATH } from "../../constants/paths";

export default {
  path: SUPPORT_PATH,
  component: Loadable({
    loader: () => import(/* webpackChunkName: 'SupportTicket' */ "./components/SupportTicket"),
    loading: LoadingSpinner,
  }),
};
