import { Loadable } from "utils/components";
import LoadingSpinner from "components/LoadingSpinner";

export default {
  path: "/upload",
  component: Loadable({
    loader: () => import(/* webpackChunkName: 'Upload' */ "./components/UploadNewModel"),
    loading: LoadingSpinner,
  }),
};
