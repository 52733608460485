import { Loadable } from "utils/components";
import CleanLayout from "../../layouts/CleanLayout";

export default {
  path: "/web-ar-extended",
  layout: CleanLayout,
  component: Loadable({
    loader: () => import(/* webpackChunkName: 'WebARExtended' */ "./WebARExtended"),
  }),
};
