import { FIREBASE_APP_SET, FIREBASE_APP_UNSET } from "./actionTypes";

const initialState = {
  isLoaded: false,
  isEmpty: true,
  ui: () => {
    return false;
  },
  orgUi: () => {
    return false;
  },
  globalUi: () => {
    return false;
  },
  isPathAllowed: () => {
    return false;
  },
  displayName: "",
};

export default function fbApp(state = initialState, action) {
  switch (action.type) {
    case FIREBASE_APP_SET:
      return action.fbApp;
    case FIREBASE_APP_UNSET:
      return initialState;
    default:
      return state;
  }
}
