import React from "react";
import { Switch, Route } from "react-router-dom";
import CoreLayout from "../layouts/CoreLayout";
import UserDashboard from "./UserDashboard";
import Login from "./Login";
import Signup from "./Signup";
import User from "./User";
import Admin from "./Admin";
import Queue from "./TaskQueue";
import ModelVersionDetail from "./ModelVersionDetail";
import Upload from "./UploadNewModel";
import UserModels from "./UserModels";
import ResetPassword from "./ResetPassword";
import AccountProposals from "./AccountProposals";
import AccountBilling from "./AccountBilling";
import AccountMessages from "./AccountMessages";
import InfoImprint from "./InfoImprint";
import InfoTerms from "./InfoTermsAndConditions";
import InfoCookies from "./InfoCookies";
import InfoData from "./InfoData";
import Task from "./Task";
import SupportTicket from "./SupportTicket";
import Tutorials from "./Tutorials";
import SalesOfferings from "./SalesOfferings";
import BackendTest from "./BackendTest";
import ThemeTest from "./ThemeTest";
import OrgAdmin from "./OrgAdmin";
import CreatePassword from "./CreatePassword";
import Bim360 from "./ForgeAppAuth";
import PublicModelView from "./PublicModelView";
import WebModels from "./WebModels";
import WebARExtended from "./WebARExtended";
import ForgeViewer from "./ForgeViewer";
import Vaillant from "./Vaillant";
import WebARTesting from "./WebARTesting";
import WebARPreview from "./WebARPreview";

export default function createRoutes() {
  return (
    <Switch>
      {
        /* Build Route components from routeSettings */
        [
          User,
          AccountBilling,
          AccountProposals,
          InfoImprint,
          InfoTerms,
          InfoCookies,
          InfoData,
          AccountMessages,
          Signup,
          Task,
          Login,
          Admin,
          BackendTest,
          ThemeTest,
          Queue,
          ModelVersionDetail,
          Upload,
          UserModels,
          SupportTicket,
          Tutorials,
          SalesOfferings,
          ResetPassword,
          OrgAdmin,
          UserDashboard,
          CreatePassword,
          Bim360,
          PublicModelView,
          WebModels,
          WebARExtended,
          ForgeViewer,
          Vaillant,
          WebARTesting,
          WebARPreview,
          /* Add More Routes Here */
        ].map((value, index) => {
          const { path, component, layout } = value;
          const Layout = layout || CoreLayout;
          const Component = component;
          return (
            <Route
              key={`Route-${index}`}
              path={path}
              exact
              render={(props) => (
                <Layout>
                  <Component {...props} />
                </Layout>
              )}
            />
          );
        })
      }
    </Switch>
  );
}
