import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  AppBar: {
    height: "4rem",
  },
  coreContainer: {
    // paddingBottom: "0.5rem"
  },
  scrollContainer: {
    position: "fixed",
    overflow: "auto",
    width: "100%",
    marginTop: theme.spacing(8),
    height: "calc(100vh - 64px)",
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(7),
      height: "calc(100vh - 56px)",
    },
  },
}));

export const cookieStyles = {
  cookieDecline: {
    color: "#fff",
    fontSize: "13px",
    background: "red",
  },
  cookieAccept: {
    color: "#fff",
    fontSize: "13px",
    background: "#207ffd",
    marginRight: "150px",
  },
  cookieBar: {
    background: "#2B373B",
  },
  cookieLink: {
    color: "#6cadff",
  },
};
