import Loadable from "react-loadable";
import { VAILLANT_PATHS } from "../../constants/paths";
import CleanLayout from "../../layouts/CleanLayout";

export default {
  path: VAILLANT_PATHS,
  component: Loadable({
    loader: () => import(/* webpackChunkName: 'Vaillant' */ "./Vaillant"),
    loading: () => null,
  }),
  layout: CleanLayout,
};
