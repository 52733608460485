import Loadable from "react-loadable";
import LoadingSpinner from "components/LoadingSpinner";
import { MODELS_SECTIONS_PATH } from "../../constants/paths";

// Sync route definition
export default {
  path: MODELS_SECTIONS_PATH,
  component: Loadable({
    loader: () => import(/* webpackChunkName: 'UserModels' */ "./components/UserModels"),
    loading: LoadingSpinner,
  }),
};
