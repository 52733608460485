import Loadable from "react-loadable";
import LoadingSpinner from "components/LoadingSpinner";

// Sync route definition
export default {
  path: "/account-messages",
  component: Loadable({
    loader: () => import(/* webpackChunkName: 'AccountMessages' */ "./components/AccountMessages"),
    loading: LoadingSpinner,
  }),
};
