import Loadable from "react-loadable";
import LoadingSpinner from "components/LoadingSpinner";

// Sync route definition
export default {
  path: "/account-billing",
  component: Loadable({
    loader: () => import(/* webpackChunkName: 'AccountBilling' */ "./components/AccountBilling"),
    loading: LoadingSpinner,
  }),
};
