import Loadable from "react-loadable";
import LoadingSpinner from "components/LoadingSpinner";
import { FORGE_VIEWER_PATH as path } from "../../constants/paths";
import CleanLayout from "../../layouts/CleanLayout";

// Sync route definition
export default {
  // Projects are shown for now but, path and NavBar is like Models but data and view is project
  path,
  component: Loadable({
    loader: () => import(/* webpackChunkName: 'Project' */ "./components/ForgeViewerPage"),
    loading: LoadingSpinner,
  }),
  layout: CleanLayout,
};
