import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Notifications } from "modules/notification";
import { HUBSPOT_CHATBOT_CONTAINER_ID } from "../../constants/constants";

function CleanLayout({ children }) {
  useEffect(() => {
    const hubspotChatbotContainer = document.getElementById(HUBSPOT_CHATBOT_CONTAINER_ID);
    if (hubspotChatbotContainer?.style) Object.assign(hubspotChatbotContainer.style, { visibility: "hidden" });
  }, []);

  return (
    <>
      {children}
      <Notifications />
    </>
  );
}

CleanLayout.propTypes = {
  children: PropTypes.element.isRequired,
};

export default CleanLayout;
