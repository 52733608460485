export const DETACHED_AUTH_APP = "detachedAuthApp";
export const LOCALHOST = "localhost";
export const LOCALHOST_URL = "http://localhost";
export const CLOUDFUNCTIONS_URL = "cloudfunctions.net";
export const HUBSPOT_SCRIPT_ID = "hs-script-loader";
export const HUBSPOT_SCRIPT_SRC = "//js.hs-scripts.com/4505988.js";
export const HUBSPOT_CHATBOT_CONTAINER_ID = "hubspot-messages-iframe-container";
export const BIM_360_ISSUE_EXTENSION = "BIM360IssueExtension";

// Columbus Circle
export const DEFAULT_LAT_LNG = { lat: 40.7680043, lng: -73.9818836 };
export const APPLE_ANON_EMAIL = "@privaterelay.appleid.com";

export const OBJECT_EXT = [
  ".fbx",
  ".skp",
  ".obj",
  ".ifc",
  ".rvt",
  ".rfa",
  ".rte",
  ".rft",
  ".sat",
  ".nwd",
  ".nwf",
  ".nwc",
  ".cad",
  ".max",
  ".3dm",
  ".3ds",
  ".glt",
  ".glb",
  ".gltf",
  ".dgn",
  ".dwf",
  ".dwg",
  ".dxf",
  ".stl",
  ".usdz",
  ".dae",
  ".ptx",
  ".sldprt",
  ".pln",
  ".pla",
];
export const MEDIA_EXT = [
  ".png",
  ".jpg",
  ".jpeg",
  ".mat",
  ".mtl",
  ".tiff",
  ".svg",
  ".zip",
  ".rar",
  ".7z",
  ".bmp",
  ".tga",
  ".exr",
  ".gif",
  ".psd",
  ".ai",
  ".iff",
  ".pict",
  ".heic",
];

export const MEDIA_EXT_QUEUE = [".mp4", ".mp3"];

export const WEB_PREVIEW_HEX_CODE = "#00b9c1";

export const ALEC_ORG_ID = "-MUT0vTc2a5Xt7KqxZSl";
