import { FirebaseApp } from "akr-cloud/lib/core/infra/FirebaseApp";
import { FIREBASE_APP_SET, FIREBASE_APP_UNSET } from "./actionTypes";

export function createFirebaseApp(firebase) {
  return async (dispatch) => {
    const res = await FirebaseApp.create(firebase);
    if (res.isOk) {
      const fbApp = res.getValue();
      dispatch({ type: FIREBASE_APP_SET, fbApp });
    } else {
      //TODO: handle errors better (e.g. showError)
      dispatch({ type: FIREBASE_APP_UNSET });
    }
  };
}

export function destroyFirebaseApp() {
  return { type: FIREBASE_APP_UNSET };
}
