import { handle } from "utils/utils";
import { cloudMessagingKey } from "config";
import { Tracker } from "akr-cloud/lib/core/domain/Tracker";

export class CloudMessaging {
  fb;
  userId;
  messaging;
  currentToken;
  currentTokenRef;
  tokenSentToServer;
  constructor(fb) {
    // console.log(fb);
    this.fb = fb;
    if (fb.messaging.isSupported()) {
      this.messaging = fb.messaging();
      this.messaging.usePublicVapidKey(cloudMessagingKey);
    }
  }
  get isInitialized() {
    return !!this.userId;
  }
  get isSupported() {
    return this.fb.messaging.isSupported();
  }

  async init(userId) {
    this.userId = userId;

    await this.refreshToken();
    await this.registerTokenRefreshHandler();
    this.registerOnMessage();

    return this;
  }
  async refreshToken() {
    // console.log("refreshToken");
    this.messaging
      .getToken()
      .then(async (currentToken) => {
        if (currentToken) {
          this.setCurrentToken(currentToken);
          await this.addCurrentTokenToDb();
          this.tokenSentToServer = true;
        } else {
          // Show permission request.
          // console.log("No Instance ID token available. Request permission to generate one.");
          // Show permission UI.
          this.tokenSentToServer = false;
        }
      })
      .catch((err) => {
        // console.log("An error occurred while retrieving token. ", err);
        this.tokenSentToServer = false;
      });
  }

  setCurrentToken(token) {
    // console.log("setCurrentToken", token);
    this.currentToken = token;
    this.currentTokenRef = this.fb.database().ref(`fcmTokens/${this.userId}/${this.currentToken}`);
  }

  async addCurrentTokenToDb() {
    // console.log("addCurrentTokenToDb");
    const [rtErr, regToken] = await handle(this.currentTokenRef.once("value"));
    if (rtErr) throw new Error(`Fetch token from db failed: ${rtErr}`);
    if (!regToken.val()) {
      this.currentTokenRef.set(Tracker.newDTOFromWeb(this.userId));
      this.tokenSentToServer = true;
    }
  }

  registerTokenRefreshHandler() {
    // console.log("registerTokenRefreshHandler");
    this.messaging.onTokenRefresh(async () => {
      // console.log("onTokenRefresh");
      await this.refreshToken();
    });
  }
  registerOnMessage() {
    // console.log("registerOnMessage");
    this.messaging.onMessage((payload) => {
      // console.log("Message received", payload);
    });
  }
}
