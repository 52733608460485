//module_descriptiveMessageName_postfix1_postfix2
//Postfix examples: title, label,tooltip, detail,expl , question,abbr, long, short, answer,

export const Errors = {
  failedToLoadClaims: "Failed to load user authentication info.",
  failedToLoadFromDB: "Failed to load data from database",
  failedToLoadUserInfo: "Failed to load user info",
  modelsLoadingFailed: "Failed to load models",
  modelLoadingFailed: "Failed to load model",
  saveFailed: "Save failed",
  deleteFailed: "Delete failed",
  downloadFailed: "Download failed",
  invalidRequest: "Invalid request",
  openPreviewFailed: "Open preview failed",
  sendSignInLinkToEmailFailed: "Sending sign-in link to email failed",
  invalidCode: "Invalid code",
  invalidAction: "Invalid action",
  invalidCodeAndRedirect: "Invalid code. You will be redirected to Login page.",
  groupUpdateFailed: "Failed to update groups.",
  modelInfoNotFound: "Model info not found.",
};
export const Warnings = {
  allGroupsRemoval: "You are about to remove this model from all your groups. Are you sure you want to proceed?",
  modelRemoval: "You are about to delete your model. Are you sure?",
  onlyOneUSDZ: "Only one USDZ file allowed.",
  onlyOneGLT: "Only one GLT file allowed.",
  onlyOne3DFile: "Only one 3D file allowed.",
};
export const Success = {
  modelShared: "Model has been shared!",
  fileDeleted: "File deleted",
  emailWithLinkSent: "Email with new sign-in link was sent. Please check your inbox.",
  passwordCreatedSuccessfully: "Password created successfully.",
  passwordChangedSuccessfully: "Password changed successfully.",
  emailVerifiedSuccessfully: "Your email address was verified successfully.",
  groupUpdate: "Groups were updated successfully.",
  previewApproved: "Preview was approved.",
  previewDeclined: "Preview was declined.",
  diagnosticReportSent: "Diagnostic report was sent.",
  processingReportSent: "Processing report was sent.",
  reviewApproved: "Review was approved.",
  reviewDeclined: "Review was declined.",
  modelDeleted: "Model was deleted.",
};

export const ValidationsError = {
  emailExists: "Email already registered",
};

export const Info = {
  modelRemoved: "This model has been removed.",
  modelDoesNotExists: "Model does not exist",
  modelViewUnavailable: "Model view unavailable",
  userFound: "User found!",
  userNotFound: "User not found",
  userInvalidData: "User has invalid data. Contact support",
  uploader_uploadInterrupted: "Upload interrupted",
  contactDev: "Please contact dev support",
  uploader_diagnosticsMissing: "Fill out diagnostic form before uploading any assets.",
  uploader_alreadyPublished:
    "Project is already published. Published files should not be overwritten in standard process. By doing that task will be set to 'In progress' status and you have to approve it again. Hope you have good reason for that ;)",
  uploader_noAssets: "No assets for publishing yet. Hurry up and upload some shiny stuff :)",
  uploader_previewUsed: "Used copy of assets from automatic preview",
  uploader_filesDeleted:
    "Watch out!! Current assets uploaded for publishing will be deleted at the beginning of the upload process. Files created by automatic preview will stay intact.",
  uploader_instructions: "Upload all required files and submit",
  uploader_requiredAssets:
    "For correct display of models, following is required: modelInfo.json, footprint.png, ANDROID_BUNDLE, IOS_BUNDLE, WEBGL_BUNDLE ",
  canceled: "Action canceled",
  failed: "Action failed",
  success: "Action successful",
  task_archiveAction_title: "Archive Task",
  task_archiveAction_descr:
    ' This will remove task from queue. It will be placed under "ARCHIVED" filter. During standard processing task\n' +
    '          should end with "Approved Lead review". Make sure that you have good reason confirmed by lead for archiving this task.',
  taskCopyButtonDisabledTooltip: "Copy is available only for manual tasks with already uploaded files",
  previewNotExists: "Preview does not exist",
  taskModelIsNotReadyTooltip: "Model not ready. Successful preview or manual file upload is required.",
  uploadNotReadyTooltip: "Upload available only for your tasks with submitted diagnostic report.",
  task_alreadyAssigned_tooltip: "This task is already assigned. You are not allowed to make any manipulation.",
  noAssets: "No assets to display",
  noFiles: "No files to display",
  accountUpdated: "Account information updated",
  resetPassword: "Reset password",
  commentAdded: "Comment saved successfully.",
  commentCleared: "Comment deleted successfully.",
};
export const Suggestions = {
  logOutLogIn: "Please to try to log out and log in again.",
};

export const Devel = {
  dbIncorrectClass: "Passed incorrect object type into db layer",
};
export const BundleName = {
  ANDROID_BUNDLE: "Android",
  IOS_BUNDLE: "IOS",
  WEBGL_BUNDLE: "WebGL",
};

export const AugImg = {
  dialogName: "AR Images",
  loadFailed: "Failed to load AR images",
  deleteFailed: "Failed to delete AR image.",
  deleteSuccess: "AR Image was deleted.",
  uploadFailed: "Failed to upload AR image.",
  uploadSuccess: "AR Image uploaded successfully.",
  uploadInstructions: [
    "Please use JPEG file formats",
    "Minimum image dimensions are 480 x 480 pixels",
    "Below please enter the actual width of the image in meters as displayed on location",
  ],
};

export const AddUser = {
  Status: {
    MEMBER: "Already Created",
    ALREADY_INVITED: "Already Invited",
    EXISTS: "Registered to another organization",
    ERROR: "Error",
    SUCCESS: "Success",
  },
  SupportEnquiry: {
    requestMigration: (email) => `Migration request for email: ${email}`,
    reportError: (email) => `Create user error for email: ${email}`,
  },
  Create: "Create user immediately",
  Invite: "Send invite email",
  SubmitText: {
    default: "Add user",
    addMultiple: (count) => `Add ${count} users`,
  },
  maxEmails: (max) => `Enter up to ${max} email addresses, separated by a comma.`,
  DialogTitleInvite: "Invited user(s)",
  DialogTitleCreate: "Created user(s)",
};

export const DeleteUser = {
  titleOwn: "Account deletion",
  bodyOwn: "You are about to delete your account. Are you sure?",
  title: "Remove user",
  body: (username) => `Are you sure you want to remove user ${username} and all his data?`,
  success: "User was removed successfully.",
  error: "Failed to remove user.",
};
export const RemoveUnit = {
  title: "Remove Group",
  body: () =>
    `Are you sure you want to remove group  Users will loose access to projects and models shared with this group`,
  success: "Group was removed successfully.",
  error: "Failed to remove group.",
};

export const ResetPw = {
  linkInvalidTitle: "The sign-in link is invalid",
  linkInvalidDesc:
    "This can happen if the link is malformed, expired, or has already been used. Please request new link to continue sign-in.",
  action: "Request new sign-in link",
};

export const CreatePw = {
  title: "Create password for your new account",
};

export const TaskStatusName = {
  STARTED: "Started",
  CREATED: "Created",
  WAITING: "Waiting",
  IN_PROGRESS: "In progress",
  DONE_SUCCESS: "Processed (success)",
  DONE_ERROR: "Processed (error)",
  CANCELED: "Deleted (user)",
  SKIPPED: "Skipped (auto)",
  APPROVED: "Approved",
  DECLINED: "Declined",
  ARCHIVED: "Archived",
  COPIED: "Copied",
};
export const JiraTicket = {
  submitSuccess: "Your enquiry was submitted successfully.",
  submitFailed: "Failed to submit enquiry, please try again.",
};
export const FileGroupName = {
  flatSteps: "Steps",
  preview: "Files from automatic preview",
  original: "Uploaded file(s)",
  published: "Files for publishing",
};
export const FileCategoryName = {
  assetBundle: "Asset Bundles",
  media: "Media Files",
  model: "Original Model",
  misc: "Additional Files",
};
export const WorkspaceName = { org: "Organization workspace", public: "Public workspace", user: "Private workspace" };
export const WorkspaceNoModels = {
  user: "No models in your private workspace. Check the organization workspace.",
  org: "No models in your organization workspace. Check your private workspace.",
  public: "No models for sharing with public.",
};
export const PushNotifications = {
  previewApproved: (projectName) => {
    return {
      title: "Preview is approved",
      body: `Automatic preview for "${projectName}" is approved. `,
    };
  },
  modelApproved: (projectName) => {
    return {
      title: "Validated model is ready",
      body: `${projectName} has been fully processed and is ready.`,
    };
  },
  modelIssue: (projectName) => {
    return {
      title: "Problematic upload",
      body: `Upload for ${projectName} contains problematic files. Please check your email for more info.`,
    };
  },
};

export const TaskCopyDialogInfo = {
  txt: (execMode) => {
    switch (execMode) {
      case "SUPPORT":
        return {
          destEmail_label: "Destination user email",
          destEmail_placeholder: "Exact email of user to whom model will be copied",
          modelName_label: "Model name",
          modelName_placeholder: "Name the copy version of model",
          searchUserAction_label: "Check user",
          copyModelAction_label: "Copy",
        };
      default:
        return {
          destEmail_label: "Destination user email",
          destEmail_placeholder: "Exact email of user to whom model will be copied",
          modelName_label: "Model name",
          modelName_placeholder: "Name the version of model",
          searchUserAction_label: "Check user",
          copyModelAction_label: "Share",
        };
    }
  },
};

export const Generic = {
  update_label: "Update",
};

export const PlatformStatsMap = {
  assetBundlesSize: "Asset Bundle Size",
  orgsCnt: "Organizations Count",
  originalFilesSize: "Original Files Size",
  publishedProjectsCnt: "Published Projects Count",
  totalProjectsCnt: "Total Projects Count",
  totalPublishedProjectsCnt: "Total Published Projects Count",
  tracker: {
    createdApp: "Created App",
    createdAt: "Created At",
    createdBy: "Created By",
    updatedApp: "Updated App",
    updatedAt: "Updated At",
    updatedBy: "Updated By",
  },
  usedOrgsCnt: "Used Organizations Count",
  usedOrgsUsersCnt: "Used Organization Users Count",
  usersCnt: "Users Count",
};

export const IssueMap = {
  title: "Title",
  description: "Description",
  dueDate: "Due Date",
  assignedTo: "Assigned To",
  owner: "Issue Owner",
  lbsLocation: "Location",
  locationDescription: "Location Details",
  status: "Status",
  issueType: "Type",
  issueSubType: "Sub-Type",
};

export const ForgeAuthStatus = {
  SESSION_VALID: "Authorization successful",
  SESSION_MISSING: "Not authorized.",
  SESSION_EXPIRED: "Authorization expired.",
  AUTHENTICATING: "Redirecting to Autodesk",
  PROCESSING_CODE: "Processing permissions",
  AUTHENTICATION_FAILED: "Authorization failed",
  VALIDATING_SESSION: "Checking status",
  INITIALIZING: "Initializing",
};
export const ForgeAuthInfo = {
  redirectFailed: "Redirect to original page failed",
  invalidUrl: "Invalid redirect url",
  loginInfo: "Login to Autodesk to authenticate AKULAR app",
  allOkInfo: "Successfully connected to Autodesk",
  notHandledStatus: "Session status not handled",
  redirecting: "Redirecting back",
};
export const ForgeAuthErrors = {
  access_denied: "User denied Authorization",
};

export const ForgeOAuthActions = {
  fetchProfile: "Loading profile info",
  fetchPublicToken: "Loading existing token",
  fetchAuthUrl: "Requesting authorization url",
  processUrlQuery: "Processing authorization response",
  signOut: "Signing out",
  checkSession: "Validating authorization token",
  redirecting: "Redirecting",
};
export const ForgeIssueMessages = {
  issueCreateFailed: "Failed to create issue.",
  issueCreated: "Issue was created successfully.",
  placeIssue: "Click a location to place the issue push pin.",
  issueUpdateFailed: "Issue editing failed.",
  issueUpdated: "Changes saved.",
  issueLoadFailed: "Failed to load issues.",
};
