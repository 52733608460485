export const UPLOAD_PATH = "/upload";
export const ACCOUNT_PATH = "/account-settings";
export const LOGIN_PATH = "/login";
export const SIGNUP_PATH = "/signup";
export const ADMIN_PATH = "/admin";
export const BACKEND_TEST_PATH = "/backend-test";
export const THEME_TEST_PATH = "/theme-test";

export const ORG_ADMIN_BASE_PATH = "/org-admin";
export const ORG_ADMIN_SECTION_PATH = "/org-admin/:sectionId";
export const ORG_ADMIN_ACCOUNT_PATH = "/org-admin/account";
export const ORG_ADMIN_UNITS_PATH = "/org-admin/groups";
export const ORG_ADMIN_UNIT_EDIT_PATH = "/org-admin/group-edit";
export const ORG_ADMIN_USERS_PATH = "/org-admin/users";
export const ORG_ADMIN_USER_EDIT_PATH = "/org-admin/user-edit";
export const ORG_ADMIN_USER_DETAIL_PATH = "/org-admin/user-detail";
export const ORG_ADMIN_USER_ADD_PATH = "/org-admin/user-add";
export const ORG_ADMIN_ROLES_PATH = "/org-admin/roles";
export const ORG_ADMIN_SETTINGS_PATH = "/org-admin/settings";

export const QUEUE_PATH = "/queue";
export const PROJECT_PATH = "/project";
export const PROJECTS_PATH = "/projects";
export const DASHBOARD_PATH = "/dashboard";
export const TASKS_PATH = "/tasks";
export const TASK_BASE_PATH = "/task";
export const TASK_PATH = "/task/:taskId";
export const MODELS_BASE_PATH = "/models";
export const MODELS_SECTIONS_PATH = "/models/:workspaceId";
export const MODEL_USER_PATH = "/model/user";
export const MODELS_USER_PATH = "/models/user";
export const MODELS_ORG_PATH = "/models/org";
export const MODELS_PUBLIC_PATH = "/models/public";
export const TUTORIALS_PATH = "/tutorials";
export const PASSWORD_RESET_PATH = "/resetpassword";
export const MODEL_PATH = "/model/:workspaceId/:projectId/:version";
export const MODEL_BASE_PATH = "/model";
export const FORGE_VIEWER_PATH = "/fviewer/:workspaceId/:projectId/:modelVersionOrdKey";
export const FORGE_VIEWER_BASE_PATH = "/fviewer";
export const SALE_OFFERING_PATH = "/offers";
export const SUPPORT_PATH = "/support";
export const CREATE_PASSWORD_PATH = "/createpassword";
export const FORGE_APP_AUTH_PATH = "/forge-app-auth/:forgeAppId";
export const PUBLIC_MODEL_VIEW_PATH = "/shared";
export const WEB_MODELS_PATH = "/web-models";
export const WEB_MODELS_BASE_PATH = "/web-models";
export const SEND_INVITE_PATH = "/send-invite";
export const WEB_AR_TESTING_PATH = "/web-ar-testing";
export const WEB_AR_PREVIEW_BASE_PATH = "/web-ar";
export const WEB_AR_PREVIEW_PATH = "/web-ar/:shortId";

export const VAILLANT_PATH = "/vaillant/:locale";
export const VAILLANT_MODELS_PATH = "/vaillant/:locale/models";
export const VAILLANT_MODEL_PATH = "/vaillant/:locale/models/:familyId";
export const VAILLANT_MODEL_INFORMATION_PATH = "/vaillant/:locale/models/:familyId/information";
export const VAILLANT_MODEL_MATCHING_UNITS_PATH = "/vaillant/:locale/models/:familyId/matching-units";

export const VAILLANT_PATHS = [
  VAILLANT_PATH,
  VAILLANT_MODELS_PATH,
  VAILLANT_MODEL_PATH,
  VAILLANT_MODEL_INFORMATION_PATH,
  VAILLANT_MODEL_MATCHING_UNITS_PATH,
];

//TODO use this constants in paths
export const ALLOWABLE_PATHS = [
  DASHBOARD_PATH,
  UPLOAD_PATH,
  MODEL_BASE_PATH,
  QUEUE_PATH,
  ACCOUNT_PATH,
  SALE_OFFERING_PATH,
  SEND_INVITE_PATH,
  TUTORIALS_PATH,
  SUPPORT_PATH,
  ADMIN_PATH,
  ORG_ADMIN_UNITS_PATH,
  ORG_ADMIN_UNIT_EDIT_PATH,
  ORG_ADMIN_USERS_PATH,
  ORG_ADMIN_USER_EDIT_PATH,
  ORG_ADMIN_USER_DETAIL_PATH,
  ORG_ADMIN_USER_ADD_PATH,
  ORG_ADMIN_ROLES_PATH,
  ORG_ADMIN_SETTINGS_PATH,
];
