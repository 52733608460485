import { useEffect } from "react";
import { useFirebaseAppActions } from "../modules/firebaseApp";
import { useFirebase } from "react-redux-firebase";
import { CustomClaims } from "akr-cloud/lib/core/domain/CustomClaims";

let lastClaimsCallback = null;
let lastClaimsRef = null;
const useAuthStateListener = () => {
  const { createFirebaseApp, destroyFirebaseApp } = useFirebaseAppActions();
  const firebase = useFirebase();

  useEffect(() => {
    const unsubscribe = firebase.auth().onIdTokenChanged(function (user) {
      if (user) {
        user.getIdTokenResult().then((token) => {
          if (token.claims && token.claims.orgId) {
            createFirebaseApp(firebase);
            if (!lastClaimsCallback) {
              lastClaimsRef = `customClaims/${user.uid}`;
              lastClaimsCallback = firebase
                .database()
                .ref(lastClaimsRef)
                .on("value", function (snap) {
                  const claimsSnap = snap.child("value");
                  if (claimsSnap.exists()) {
                    const claims = claimsSnap.val();
                    if (!CustomClaims.equalsDTO(claims, token.claims)) {
                      console.log("claims changed", snap.val());
                      user.getIdTokenResult(true);
                    }
                  }
                });
              console.log("customClaims hook on", lastClaimsRef);
            }
          }
        });
      }
      if (!user) {
        destroyFirebaseApp();
        if (lastClaimsCallback && lastClaimsRef) {
          firebase.database().ref(lastClaimsRef).off("value", lastClaimsCallback);
          lastClaimsCallback = null;
          lastClaimsRef = null;
          console.log("customClaims hook off", lastClaimsRef);
        }
      }
    });
    return unsubscribe;
  }, [createFirebaseApp, destroyFirebaseApp, firebase]);
};

export default useAuthStateListener;
