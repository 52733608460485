import { Loadable } from "utils/components";
import LoadingSpinner from "components/LoadingSpinner";
import { CREATE_PASSWORD_PATH as path } from "constants/paths";

export default {
  path,
  component: Loadable({
    loader: () => import(/* webpackChunkName: 'CreatePasswordPage' */ "./components/CreatePassword"),
    loading: LoadingSpinner,
  }),
};
