import { Loadable } from "utils/components";
import LoadingSpinner from "components/LoadingSpinner";
import { TUTORIALS_PATH as path } from "constants/paths";

export default {
  path,
  component: Loadable({
    loader: () => import(/* webpackChunkName: 'TutorialsPage' */ "./components/TutorialsPage"),
    loading: LoadingSpinner,
  }),
};
