import { combineReducers } from "redux";
import { firebaseReducer as firebase } from "react-redux-firebase";
import { reducer as form } from "redux-form";
import { reducer as notifications } from "modules/notification";
import { reducer as fbApp } from "modules/firebaseApp";
import { reducer as successfulSubmit } from "modules/successfulSubmit";
import locationReducer from "./location";
import claimsReducer from "./claims";

export function makeRootReducer(asyncReducers) {
  return combineReducers({
    // Add sync reducers here
    firebase,
    form,
    claims: claimsReducer,
    notifications,
    fbApp,
    successfulSubmit,
    location: locationReducer,
    ...asyncReducers,
  });
}

export function injectReducer(store, { key, reducer }) {
  store.asyncReducers[key] = reducer; // eslint-disable-line no-param-reassign
  store.replaceReducer(makeRootReducer(store.asyncReducers));
}

export default makeRootReducer;
