import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { Link as RouterLink, useHistory, useLocation } from "react-router-dom";
import Divider from "@material-ui/core/Divider";
import {
  MODELS_BASE_PATH,
  ORG_ADMIN_ACCOUNT_PATH,
  ORG_ADMIN_UNITS_PATH,
  ORG_ADMIN_BASE_PATH,
  ORG_ADMIN_USERS_PATH,
  MODELS_USER_PATH,
  MODELS_ORG_PATH,
  MODELS_PUBLIC_PATH,
} from "../../constants/paths";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import useFirebaseApp from "../../modules/firebaseApp";
import { useQuery } from "utils/hooks";
import { Alert } from "@material-ui/lab";
import { Box, Button } from "@material-ui/core";
import { WarningOutlined } from "@material-ui/icons";

export function ContentMenuItems({ classes }) {
  const { pathname } = useLocation();
  const parent = `/${pathname.split("/")[1] || ""}`;
  const fbApp = useFirebaseApp();
  const orgId = useQuery().get("orgId");

  switch (parent) {
    case MODELS_BASE_PATH:
      return (
        <>
          <ListItem>
            <Typography> Workspaces</Typography>
          </ListItem>
          <Divider />
          <ListItem className={classes.contentItemsSection}>
            <List>
              <ListItem
                className={pathname.startsWith(MODELS_USER_PATH) ? classes.active : classes.clickable}
                component={RouterLink}
                to={MODELS_USER_PATH}>
                <ListItemText primary="Private" />
              </ListItem>
              <ListItem
                className={pathname.startsWith(MODELS_ORG_PATH) ? classes.active : classes.clickable}
                component={RouterLink}
                to={MODELS_ORG_PATH}>
                <ListItemText primary="Organization" />
              </ListItem>
              <ListItem
                className={pathname.startsWith(MODELS_PUBLIC_PATH) ? classes.active : classes.clickable}
                component={RouterLink}
                to={MODELS_PUBLIC_PATH}>
                <ListItemText primary="Public" />
              </ListItem>
            </List>
          </ListItem>
          <Divider />
        </>
      );
    case ORG_ADMIN_BASE_PATH:
      return (
        <>
          <ListItem>
            <Typography> Organization</Typography>
          </ListItem>
          <Divider />
          <List className={classes.contentItemsSection}>
            {fbApp.ui("orgAdminUnits", ["R"]) && (
              <ListItem
                className={pathname.startsWith("/org-admin/group") ? classes.active : classes.clickable}
                component={RouterLink}
                to={ORG_ADMIN_UNITS_PATH + `${orgId ? `?orgId=${orgId}` : ""}`}>
                <ListItemText primary="Groups" />
              </ListItem>
            )}
            {fbApp.ui("orgAdminUsers", ["R"]) && (
              <ListItem
                className={pathname.startsWith("/org-admin/user") ? classes.active : classes.clickable}
                component={RouterLink}
                to={ORG_ADMIN_USERS_PATH + `${orgId ? `?orgId=${orgId}` : ""}`}>
                <ListItemText primary="User accounts" />
              </ListItem>
            )}
            {fbApp.ui("orgAdminOrgDetails", ["R"]) && (
              <ListItem
                className={pathname.startsWith(ORG_ADMIN_ACCOUNT_PATH) ? classes.active : classes.clickable}
                component={RouterLink}
                to={ORG_ADMIN_ACCOUNT_PATH + `${orgId ? `?orgId=${orgId}` : ""}`}>
                <ListItemText primary="Organization details" />
              </ListItem>
            )}
          </List>
          {/* <ListItem
            className={pathname.startsWith(ORG_ADMIN_SETTINGS_PATH) ? classes.active : classes.clickable}
            onClick={() => menuClick(ORG_ADMIN_SETTINGS_PATH)}>
            <ListItemText primary="Settings" />
          </ListItem>*/}
          <Divider />
          {Boolean(orgId) && fbApp.ui("masterAdmin", ["R"]) && (
            <Alert icon={false} severity="warning">
              <Typography align="center">
                <WarningOutlined color="inherit" />
              </Typography>
              <Typography variant="body2" paragraph>
                You are viewing other organization as admin.
              </Typography>
              <Button
                variant="outlined"
                color="inherit"
                fullWidth
                size="small"
                disableRipple={false}
                component={RouterLink}
                to={ORG_ADMIN_USERS_PATH}>
                <Box component="span" lineHeight={1}>
                  Exit
                </Box>
              </Button>
            </Alert>
          )}
        </>
      );

    default:
      return null;
  }
}
