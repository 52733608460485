// ------------------------------------
// Constants
// ------------------------------------
export const CLAIMS = "CLAIMS";

// ------------------------------------
// Actions
// ------------------------------------
export function claimsChange(claims = {}) {
  return {
    type: CLAIMS,
    payload: claims,
  };
}

// ------------------------------------
// Specialized Action Creator
// ------------------------------------
export function updateClaims({ dispatch }) {
  return (nextClaims) => dispatch(claimsChange(nextClaims));
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {};
export default function claimsReducer(state = initialState, action) {
  return action.type === CLAIMS ? action.payload : state;
}
