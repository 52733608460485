import React from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import styles from "./FormSelectField.styles";

const useStyles = makeStyles(styles);

function FormSelectField({ input, label, tooltip, customClass, defaultValue, options, meta: { touched, invalid } }) {
  const classes = useStyles();
  const theme = useTheme();
  const globalClasses = makeStyles(theme.globalStyles)();

  return (
    <FormControl fullWidth className={customClass}>
      <InputLabel className={classes.alignLeft}>{label}</InputLabel>
      <Select
        {...input}
        fullWidth
        error={touched && invalid}
        value={input.value}
        onChange={(value) => input.onChange(value)}
        onBlur={() => input.onBlur(input.value)}>
        {options.map((option, i) => {
          if (defaultValue) {
            return (
              <MenuItem key={i} value={option.value}>
                {option.label}
              </MenuItem>
            );
          } else {
            return (
              <MenuItem key={i} value={option.value}>
                {option.label}
              </MenuItem>
            );
          }
        })}
      </Select>
      <FormHelperText>{tooltip ? tooltip : null}</FormHelperText>
    </FormControl>
  );
}

export default FormSelectField;
