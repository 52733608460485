import { useSelector } from "react-redux";
/**
 
 * @returns FirebaseApp
 */
export default function useFirebaseApp() {
  const fbApp = useSelector((state) => state.fbApp);

  return fbApp;
}
