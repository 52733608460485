export default (theme) => ({
  flex: {
    flexGrow: 1,
  },
  appBar: {
    position: "fixed",
    top: 0,
    left: 0,
    color: theme.navColor,
    backgroundColor: theme.navBgColor,
  },
  appBarTransparent: {
    position: "fixed",
    top: 0,
    left: 0,
    color: theme.navColor,
    backgroundColor: "transparent",
    boxShadow: "none",
    border: "none",
  },
  signIn: {
    textDecoration: "none",
    alignSelf: "center",
  },
  logo: {
    display: "flex",
    alignItems: "center",
  },
  appNav: {
    display: "flex",
    alignItems: "center",
  },
  appNavBtn: {
    color: theme.navColor,
    marginRight: "1rem",
    "&:hover": {
      backgroundColor: "transparent",
      color: theme.navColor,
      boxShadow: "none",
    },
  },
  appNavBtnActive: {
    color: theme.navColor,
    marginRight: "1rem",
    borderRadius: 0,
    borderBottom: "2px solid black",
    "&:hover": {
      backgroundColor: "transparent",
      color: theme.navColor,
      boxShadow: "none",
    },
  },
  clickable: {
    cursor: "pointer",
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
    cursor: "pointer",
  },
  mobileActive: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    cursor: "pointer",
  },
  mobileMenu: {
    width: "50%",
  },
  dialogTitle: {
    paddingLeft: 0,
  },
  buttonRoot: {
    color: "black",
    padding: 0,
  },
  buttonPadding: {
    paddingLeft: theme.spacing(2),
  },
  badge: {
    left: 20,
  },
  invitationGrid: {
    textAlign: "center",
    paddingTop: "1rem",
  },
  mobileMenuItems: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  inviteButton: {
    minWidth: "160px",
  },
  link: {
    color: "inherit",
    textDecoration: "none",
  },
  contentItemsSection: {
    backgroundColor: "#f2f2f254",
  },
});
