import PropTypes from "prop-types";
import useAuthStateListener from "../../hooks/useAuthStateListener";

const AuthStateListener = ({ children }) => {
  useAuthStateListener();

  return children;
};

AuthStateListener.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthStateListener;
