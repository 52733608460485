export default () => ({
  root: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "flex-start",
    paddingTop: "7rem",
    paddingBottom: "7rem",
    height: "100%",
  },
  progress: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "50%",
  },
});
