import React from "react";
import ReactDOM from "react-dom";
import { initScripts } from "./utils";
import createStore from "./store/createStore";
import { version, name } from "../package.json";
import { env } from "./config";
import App from "./containers/App";
import "./index.css";
import { registerServiceWorker } from "./modules/cloudMessaging/components/registerSw";
import preval from "preval.macro";
import "react-perfect-scrollbar/dist/css/styles.css";
// import * as serviceWorker from './serviceWorker'
import TagManager from "react-gtm-module";
import * as fbConfig from "./config";
import ReactGA from "react-ga";
ReactGA.initialize(fbConfig.gAnalyticsTrackingId, { debug: true });
// Window Variables
// ------------------------------------
window.version = version;
window.appName = name;
window.commit = preval`module.exports=require("child_process").execSync("git rev-parse --short HEAD").toString().trim();`;
window.buildTimestamp = preval`module.exports = new Date().toLocaleString();`;
window.env = env;

TagManager.initialize({ gtmId: fbConfig.gTagManagerId });

initScripts();

// Store Initialization
// ------------------------------------
const initialState = window.___INITIAL_STATE__ || {
  firebase: { authError: null },
};
const store = createStore(initialState);
const routes = require("./routes/index").default();
registerServiceWorker();
ReactDOM.render(<App store={store} routes={routes} />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister()
