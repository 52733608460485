import React from "react";
import PropTypes from "prop-types";
import Navbar from "containers/Navbar";
import { Notifications } from "modules/notification";
import { useStyles, cookieStyles } from "./CoreLayout.styles";
import Grid from "@material-ui/core/Grid";
import CookieConsent from "react-cookie-consent";
import { SuccessfulSubmitDialog } from "../../modules/successfulSubmit";
import AkularStoreFAB from "../../components/AkularStoreFAB";
import useChatbot from "../../hooks/useChatbot";

const CHATBOT_VISIBILITY = {
  VISIBLE: true,
  DONT_DISPLAY: false,
};

function CoreLayout({ children }) {
  const classes = useStyles();

  const chatbotVisible = useChatbot(CHATBOT_VISIBILITY.DONT_DISPLAY);

  return (
    <>
      <Grid container className={classes.coreContainer}>
        <Grid item xs={12}>
          <Navbar />
        </Grid>
        <Grid item xs={12} className={classes.scrollContainer} data-component={"scrollContainer"}>
          {children}
        </Grid>
        <CookieConsent
          // enableDeclineButton
          location="bottom"
          declineButtonText="I decline"
          buttonText="I accept"
          cookieName="akularCookieApprove"
          style={cookieStyles.cookieBar}
          buttonStyle={cookieStyles.cookieAccept}
          declineButtonStyle={cookieStyles.cookieDecline}
          expires={150}>
          This website uses cookies to enhance the user experience. You can read more in{" "}
          <a className={cookieStyles.cookieLink} href="/cookie-policy">
            cookie policy
          </a>
        </CookieConsent>
        <AkularStoreFAB chatbotVisible={chatbotVisible} />
      </Grid>

      <Notifications />
      <SuccessfulSubmitDialog />
    </>
  );
}

CoreLayout.propTypes = {
  children: PropTypes.element.isRequired,
};

export default CoreLayout;
