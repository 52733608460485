export const apiKey = "AIzaSyCysZpGZxZScy8rmWUUiSsAzpSPXsOysKI";

export const authDomain = "akular-prod-temp.firebaseapp.com";

export const databaseURL = "https://akular-prod-temp-default-rtdb.firebaseio.com";

export const projectId = "akular-prod-temp";

export const storageBucket = "akular-prod-temp.appspot.com";

export const messagingSenderId = "809424052385";

export const appId = "1:809424052385:web:dee279293f453e16ba10e0";

export const measurementId = "G-2G90RWJJGX";

export const hostingUrl = "https://portal.akular.com";

export const env = "prod";

export const google = {
  apiKey: "AIzaSyAArS98KGveRsYLfdGODlxNdPGxo8g3xro",
  language: "en",
};

export const cloudMessagingKey = "BG9E-qCcIxNKh-Kgbwxe-qPrAPGDs64vipFg8pz_Keay2txzvEVRXoRQkJE5Q3fDAQSR-pdKBzOWqRMRilwfWks";

export const gAnalyticsTrackingId = "UA-151453674-1";

export const gTagManagerId = "GTM-PBHMR6T";

export const functionsRegion = "us-central1";

export const emulators = {
  auth: "9099",
  functions: "5001",
  database: "9000",
  hosting: "5000",
  pubsub: "8085",
};

export const pixyzGWorkerNames = {
  0: "awsServerProd01",
  1: "legionPixyzPreview01",
};

export default { apiKey, authDomain, databaseURL, projectId, storageBucket, messagingSenderId, appId, measurementId, hostingUrl, env, google, cloudMessagingKey, gAnalyticsTrackingId, gTagManagerId, functionsRegion, emulators, pixyzGWorkerNames };
