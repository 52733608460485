import Loadable from "react-loadable";
import { WEB_AR_TESTING_PATH } from "../../constants/paths";
import CleanLayout from "../../layouts/CleanLayout";

export default {
  path: WEB_AR_TESTING_PATH,
  component: Loadable({
    loader: () => import(/* webpackChunkName: 'WebARTesting' */ "./WebARTesting"),
    loading: () => null,
  }),
  layout: CleanLayout,
};
