import Loadable from "react-loadable";
import { WEB_AR_PREVIEW_PATH } from "../../constants/paths";
import CleanLayout from "../../layouts/CleanLayout";

export default {
  path: WEB_AR_PREVIEW_PATH,
  component: Loadable({
    loader: () => import(/* webpackChunkName: 'WebARTesting' */ "./WebARPreview"),
    loading: () => null,
  }),
  layout: CleanLayout,
};
