import React from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

import SelectField from "components/FormSelectField";

import { required } from "utils/form";
import { useTheme } from "@material-ui/core/styles";
import styles from "./QueueDelayForm.styles";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(styles);

function QueueDelayForm({ pristine, submitting, handleSubmit, currentDelay }) {
  const classes = useStyles();
  const theme = useTheme();
  const globalClasses = makeStyles(theme.globalStyles)();
  const DelayOptions = [
    {
      label: "4 hours",
      value: "4",
    },
    {
      label: "6 hours",
      value: "6",
    },
    {
      label: "1 day",
      value: "24",
    },
    {
      label: "2 days",
      value: "48",
    },
    {
      label: "5 days",
      value: "120",
    },
  ];

  return (
    <form className={classes.root} onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Field
            name="delay"
            label="Change turnaround time"
            component={SelectField}
            options={DelayOptions}
            customClass={classes.field}
            validate={required}
            defaultValue={currentDelay}
          />
        </Grid>
      </Grid>

      <div className={classes.submit}>
        <Button color="primary" type="submit" variant="contained" disabled={pristine || submitting}>
          {submitting ? "Changing..." : "Change turnaround time"}
        </Button>
      </div>
    </form>
  );
}

QueueDelayForm.propTypes = {
  pristine: PropTypes.bool.isRequired, // from enhancer (reduxForm)
  submitting: PropTypes.bool.isRequired, // from enhancer (reduxForm)
  handleSubmit: PropTypes.func.isRequired, // from enhancer (reduxForm - calls onSubmit)
};

export default QueueDelayForm;
