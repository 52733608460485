import { Loadable } from "utils/components";
import { SIGNUP_PATH as path } from "constants/paths";
import LoadingSpinner from "components/LoadingSpinner";

export default {
  path,
  component: Loadable({
    loader: () => import(/* webpackChunkName: 'Signup' */ "./components/SignupPage"),
    loading: LoadingSpinner,
  }),
};
