import find from "lodash/find";
import { Errors, TaskStatusName } from "../constants/messages";
import * as moment from "moment";
import { Grid, Typography } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import React from "react";
import AndroidIcon from "@material-ui/icons/Android";
import AppleIcon from "@material-ui/icons/Apple";
import Icon from "@material-ui/core/Icon";
import isObject from "lodash/isObject";
import WebGlIcon from "../static/webgl_icon_black.svg";
import { MODELS_ORG_PATH, MODELS_PUBLIC_PATH, MODELS_USER_PATH, SIGNUP_PATH } from "../constants/paths";
import { emulators, functionsRegion, hostingUrl, projectId } from "../config";
import FileSaver from "file-saver";
import { get } from "lodash";
import { EWorkspace } from "akr-cloud/lib/common/domain/valueObjects/enums/EWorkspace";
import { Link as RouterLink } from "react-router-dom";
import { CLOUDFUNCTIONS_URL, LOCALHOST } from "../constants/constants";
import { modelVersionFullPathByTask } from "akr-cloud/lib/common/useCases/tasks/refs";
import { EContentType } from "akr-cloud/lib/common/domain/valueObjects/enums/EContentType";
import { EUnityBuildTarget } from "akr-cloud/lib/common/domain/valueObjects/enums/EUnityBuildTarget";

/**
 * @function
 * @template data
 * Promise wrapper to to catch errors. Return always resolved Promise
 * @param {Promise<data>} promise to handle
 * @returns {Promise<[undefined,data]|[Error,undefined]>}
 */
export function handle(promise) {
  return promise.then((data) => [undefined, data]).catch((error) => Promise.resolve([error, undefined]));
}

export function orderChangeHandlerFactory(items) {
  return function (key, newPosition) {
    const itemsCopy = [...items];
    const item = find(itemsCopy, (item) => {
      return item.key === key;
    });
    console.log("found", item);
    itemsCopy[newPosition - 1] = item;
    itemsCopy[item.getOrder() - 1] = items[newPosition - 1];

    for (let i = 0; i < itemsCopy.length; i++) {
      itemsCopy[i].setOrder(i + 1);
    }

    return itemsCopy;
  };
}

export function colorFromStatus(status) {
  switch (status) {
    case "ARCHIVED":
      return "grey";
    case "WAITING":
    case "IN_PROGRESS":
      return "orange";
    case "SUBMITTED":
    case "APPROVED":
    case "PUBLISHED":
    case "DONE_SUCCESS":
    case "COPIED":
      return "green";
    case "DECLINED":
    case "CANCELED":
    case "RETURNED":
    case "DONE_ERROR":
      return "red";
    default:
      return "orange";
  }
}

export const timeDiff = (lastTime, currTime) => {
  const lastActionTime = moment(lastTime);
  const thisActionTime = moment(currTime);
  const diffTime = thisActionTime - lastActionTime;
  const leftTime = moment.duration(diffTime);
  if (leftTime.asHours() > 24) {
    return `${leftTime.asDays().toFixed(0)} days`;
  }
  if (leftTime.asMinutes() > 60) {
    return `${leftTime.asHours().toFixed(0)} hours`;
  }
  if (leftTime.asMinutes() < 1) {
    return `${leftTime.asSeconds().toFixed(0)} seconds`;
  }

  return `${leftTime.asMinutes().toFixed(0)} minutes`;
};

export function InfoCard({ classes, message }) {
  return (
    <Grid
      container
      spacing={0}
      direction="row"
      justifyContent="center"
      alignContent="center"
      alignItems="center"
      className={classes.topPaddingHalfRem}>
      <Grid item xs={8}>
        <Paper>
          <Typography className={classes.infoCard}>{message}</Typography>
        </Paper>
      </Grid>
    </Grid>
  );
}
export function InfoCard2({ classes, message }) {
  return (
    <Grid
      container
      className={classes.heightFillAvailable}
      direction="row"
      justifyContent="center"
      alignContent="center"
      alignItems="center">
      <Grid item>
        <Typography className={classes.infoCard}>{message}</Typography>
      </Grid>
    </Grid>
  );
}

export function minimalActivePeriodUtcUnixMillis() {
  return moment().utc().subtract(15, "minutes").valueOf();
}

export function daysAgoUtcUnixMillis(days) {
  return moment().utc().subtract(days, "days").valueOf();
}

export function BundleIcon({ name, classes }) {
  return (
    <>
      {name === "ANDROID_BUNDLE" ? <AndroidIcon className={classes.titleIcon} /> : null}
      {name === "IOS_BUNDLE" ? <AppleIcon className={classes.titleIcon} /> : null}
      {name === "WEBGL_BUNDLE" ? <Icon src={WebGlIcon} component={"img"} className={classes.titleIcon} /> : null}
    </>
  );
}
export function modelVersionByTask({ model, task }) {
  return Array.isArray(model.modelVersions) ? model.modelVersions[task.modelVersionOrdKey] : null;
}

export async function fetchBoundingBox({ fbApp, modelVersionRef }) {
  return (await fbApp.db.ref(`${modelVersionRef}/boundingBox`).once("value")).val();
}
export async function fetchFootprint({ fbApp, modelVersionRef, task }) {
  const footprintPath = `${modelVersionFullPathByTask(task)}/published/media/footprint.png`;
  return (
    await fbApp.db
      .ref(`${modelVersionRef}/files/published/media`)
      .orderByChild("fullPath")
      .equalTo(footprintPath)
      .once("value")
  ).val();
}
export async function fetchAndroidBundle({ fbApp, modelVersionRef, task }) {
  const fullPath = `${modelVersionFullPathByTask(task)}/published/assetBundle/ANDROID/ANDROID_BUNDLE`;
  return (
    await fbApp.db
      .ref(`${modelVersionRef}/files/published/assetBundle`)
      .orderByChild("fullPath")
      .equalTo(fullPath)
      .once("value")
  ).val();
}
export async function fetchIosBundle({ fbApp, modelVersionRef, task }) {
  const fullPath = `${modelVersionFullPathByTask(task)}/published/assetBundle/IOS/IOS_BUNDLE`;
  return (
    await fbApp.db
      .ref(`${modelVersionRef}/files/published/assetBundle`)
      .orderByChild("fullPath")
      .equalTo(fullPath)
      .once("value")
  ).val();
}
export async function fetchWebGlBundle({ fbApp, modelVersionRef, task }) {
  const fullPath = `${modelVersionFullPathByTask(task)}/published/assetBundle/WEBGL/WEBGL_BUNDLE`;
  return (
    await fbApp.db
      .ref(`${modelVersionRef}/files/published/assetBundle`)
      .orderByChild("fullPath")
      .equalTo(fullPath)
      .once("value")
  ).val();
}

export function getPreviewStatusByTask(task) {
  return task.siblings && TaskStatusName[task.siblings[Object.keys(task.siblings)[0]].status]
    ? TaskStatusName[task.siblings[Object.keys(task.siblings)[0]].status]
    : " - ";
}
export function getStatus(task) {
  return TaskStatusName[task.status] || " Wrong state";
}

export function getProcessNewModelVersionTask(tasks) {
  if (!tasks || !isObject(tasks)) return null;
  const res = Object.entries(tasks).filter(([taskId, task]) => task.taskFlowKey === "ProcessNewModelVersion");
  if (res[0] && res[0][0]) return res[0][0];
  return null;
}

export function trimmedEmail(email) {
  return typeof email === "string" && email.slice(0, email.indexOf("@"));
}

export function trimmedString(str, value) {
  if (str == null) return "empty";
  if (typeof str !== "string") str = str.toString();
  if (str.length < value) return str;
  return str.slice(0, value) + "...";
}

export function trimmedEmailFromTask(task) {
  return task.assignedToUserEmail
    ? trimmedEmail(task.assignedToUserEmail)
    : task.assignedToUserId
    ? task.assignedToUserId
    : "-";
}

export const turnAroundToString = (val) => {
  const intVal = parseInt(val);
  if (intVal >= 24) {
    if (intVal === 24) {
      return `${intVal / 24} day`;
    } else {
      return `${intVal / 24} days`;
    }
  } else {
    if (intVal === 1) {
      return `${intVal} hour`;
    } else {
      return `${intVal} hours`;
    }
  }
};

export const getInitials = (name = "") => {
  return name
    .replace(/\s+/, " ")
    .split(" ")
    .slice(0, 2)
    .map((v) => v && v[0].toUpperCase())
    .join("");
};

export const wait = (time) => {
  return new Promise((res) => setTimeout(res, time));
};

export const emailDataOneReceiver = (email, templateId, actionId, params) => {
  return {
    to: [{ email, name: email }],
    params: { toName: email, ...params },
    templateId,
    actionId,
  };
};
export const emailDataMultipleReceivers = (emails, templateId, actionId, params) => {
  return {
    to: emails.map((email) => {
      return { email, name: email };
    }),
    params: { ...params },
    templateId,
    actionId,
  };
};

export function orgSignUpLink(inviteId) {
  return `${clearHttp(hostingUrl)}/${SIGNUP_PATH}?orgInviteId=${inviteId}`;
}
export function portalHref(pathname, query) {
  return `${clearHttp(hostingUrl)}/${pathname}${query ? query : ""}`;
}

export function clearHttp(url) {
  if (url.includes("https://")) return url.slice(8, url.length);
  if (url.includes("http://")) return url.slice(7, url.length);
}

/**
 * @param {{ pathname, search, hash, state }} location
 * @param {string} id
 */
export function extractId(location, id) {
  return new URLSearchParams(location.search).get(id);
}
export function isUserRecordValid(data) {
  return data && data.email && data.uid && data.customClaims && data.customClaims.orgId;
}

export function pseudoRandom(pow) {
  return Math.round(Math.random() * Math.pow(10, pow)).toString(36);
}
export function nowMillisString(numOfChars) {
  const str = Date.now().toString();
  str.substring(str.length - Math.min(numOfChars || str.length, str.length));
}

export function formatBytes(bytes, decimals = 2) {
  if (!bytes || bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export const nameFromFilePath = (fullPath) => fullPath.substr(fullPath.lastIndexOf("/") + 1);

export const downloadAndSaveFile = async ({ firebase, path, changeProgressValue }) => {
  const ref = firebase.storage().ref(path);
  const name = nameFromFilePath(path);

  const [urlError, url] = await handle(ref.getDownloadURL());
  if (urlError) {
    throw new Error(Errors.downloadFailed);
  }

  await saveFile(url, name, changeProgressValue);
};

export const saveFile = (url, name, changeProgressValue) =>
  new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.responseType = "blob";
    xhr.onload = function () {
      FileSaver.saveAs(xhr.response, name);
      resolve();
    };
    xhr.onprogress = function (event) {
      if (changeProgressValue) {
        const progress = Math.floor((event.loaded / event.total) * 100);
        changeProgressValue(progress);
      }
    };
    xhr.onerror = function () {
      reject(Errors.downloadFailed);
    };
    xhr.open("GET", url);
    xhr.send();
  });

export const openFilePreview = async ({ firebase, path }) => {
  const ref = firebase.storage().ref(path);

  const [urlError, url] = await handle(ref.getDownloadURL());
  if (urlError) {
    throw new Error(Errors.openPreviewFailed);
  }

  window.open(url);
};

export const downloadModelInfo = async ({ firebase, taskId, changeProgressValue }) => {
  const ref = firebase.database().ref(`modelInfo/${taskId}`);

  const [err, res] = await handle(ref.once("value"));
  changeProgressValue(100);
  if (err) throw new Error(Errors.downloadFailed);
  if (!res.exists()) throw new Error(Errors.modelInfoNotFound);

  const formatted = JSON.stringify(res.val(), null, 4);
  const blob = new Blob([formatted], { type: EContentType.V.JSON });
  FileSaver.saveAs(blob, "modelInfo.json");
};

export class SortList {
  static descendingComparator(a, b, orderBy) {
    if (get(b, orderBy, null) < get(a, orderBy, null)) {
      return -1;
    }
    if (get(b, orderBy, null) > get(a, orderBy, null)) {
      return 1;
    }
    return 0;
  }

  static getComparator(order, orderBy) {
    const orderByArr = orderBy.split(".");
    return order === "desc"
      ? (a, b) => SortList.descendingComparator(a, b, orderByArr)
      : (a, b) => -SortList.descendingComparator(a, b, orderByArr);
  }

  static applySort(items, sort) {
    const [orderBy, order] = sort.split("|");
    const comparator = SortList.getComparator(order, orderBy);
    const stabilizedThis = items.map((el, index) => [el, index]);

    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);

      if (order !== 0) return order;

      return a[1] - b[1];
    });

    return stabilizedThis.map((el) => el[0]);
  }
}

/**
 * @function
 * @param {string} str
 * @returns {Array<string>}
 */
export const arrayFromCommaDelimitedString = (str) => {
  return str
    .split(",")
    .map((element) => element.trim())
    .filter(Boolean);
};

export function a11yTabProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

/**
 * @function
 * Returns new array that is cleared of removed items. It checks "removed" prop on all elements of `arr`
 * @param {Array<Object>} arr
 * @returns {Array<Object>}
 */
export const withoutRemoved = (arr) => arr.filter((el) => !el.removed);

// ALL USERS unit utils
export const hasAll = (units) => units.some((unit) => unit.idStr === "ALL");
export const onlyAll = (units) => {
  const all = units.find((unit) => unit.idStr === "ALL");
  if (!all) return [];
  return [all];
};
export const withoutAll = (units) => units.filter((unit) => unit.idStr !== "ALL");
export const optionsBySelected = (selected, all) => {
  if (hasAll(selected) && selected.length === 1) return onlyAll(all);
  else if (!hasAll(selected) && selected.length >= 1) return withoutAll(all);
  else return all;
};

/**
 * @function
 * Returns appropriate models path from user id or workspace
 * @param {string} userId
 * @returns {string}
 */
export const modelsPathFromUserId = (userId) => {
  if (userId.toUpperCase() === EWorkspace.V.ORG) return MODELS_ORG_PATH;
  if (userId.toUpperCase() === EWorkspace.V.PUBLIC) return MODELS_PUBLIC_PATH;
  return MODELS_USER_PATH;
};

export const arrayToCsv = (items, fieldNames = Object.keys(items[0]), header = fieldNames) => {
  const replacer = (key, value) => (value === null ? "" : value); // specify how you want to handle null values here
  return [
    header.join(","), // header row first
    ...items.map((row) => fieldNames.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(",")),
  ].join("\r\n");
};

export const saveBlob = (blobParts, fileName) => {
  const blob = new Blob(blobParts, { type: "text/plain;charset=utf-8" });
  FileSaver.saveAs(blob, fileName);
};
export function httpsCfUrl(name) {
  return "http://localhost:3010/" + name;
  if (isLocalhostEmulator()) {
    return `http://localhost:${emulators.functions}/${projectId}/${functionsRegion}/${name}`;
  } else {
    return `https://${functionsRegion}-${projectId}.${CLOUDFUNCTIONS_URL}/${name}`;
  }
}

export function isLocalhostEmulator() {
  return window.location.hostname === LOCALHOST && window.location.port === emulators.hosting;
}
export function isLocalhostCheck() {
  return window.location.hostname === LOCALHOST;
}

export const getNewModelVersionOrdKey = (currentOrdKeys) => {
  if (!Array.isArray(currentOrdKeys) || !currentOrdKeys.length) return null;
  const validOrdKeys = currentOrdKeys.reduce((acc, v) => {
    const parsed = parseInt(v, 10);
    if (!isNaN(parsed)) acc.push(parsed);
    return acc;
  }, []);
  if (!validOrdKeys.length) return null;
  return Math.max(...validOrdKeys) + 1;
};

export const getCategorized3DFiles = (files) => {
  let hasGlt;
  let hasUsd;
  const objectFiles = [];
  const modelViewerFiles = files.reduce((acc, file) => {
    const extension = file.path.substr(file.path.lastIndexOf("."));
    if ([".gltf", ".glb"].includes(extension)) {
      hasGlt = true;
      acc.push(file);
    } else if (extension === ".usdz") {
      hasUsd = true;
      acc.push(file);
    } else objectFiles.push(file);
    return acc;
  }, []);
  return [objectFiles, modelViewerFiles, hasGlt && hasUsd];
};

export const sanitizedModelVersionCompare = (a, b) => parseInt(a, 10) === parseInt(b, 10);

export const timestampToLocaleDate = (timestamp) =>
  new Date(timestamp).toLocaleString(navigator.language || navigator.userLanguage, {
    day: "numeric",
    month: "numeric",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });

export const modelVersionLabelColor = (publishedAt, previewAt, modelViewerAt, files) => {
  if (publishedAt) return null;
  if (previewAt && hasWebGLBundle(files)) return "secondary";
  if (modelViewerAt) return "webPreview";
  return "warning";
};

export const modelVersionLabelText = (publishedAt, previewAt, modelViewerAt, files) => {
  if (publishedAt) return null;
  if (previewAt && hasWebGLBundle(files)) return "Preview";
  if (modelViewerAt) return "Web preview";
  return "In progress";
};

export const modelVersionLabelDisabled = (publishedAt, previewAt, modelViewerAt) =>
  !publishedAt && !previewAt && !modelViewerAt;

export const modelVersionComponent = (publishedAt, previewAt, modelViewerAt, files) => {
  if (!publishedAt && (!previewAt || !hasWebGLBundle(files)) && modelViewerAt) return RouterLink;
  else return "li";
};

export const hasWebGLBundle = (files) => {
  const bundles = files?.published?.assetBundle || {};
  return Boolean(Object.values(bundles).find((bundleFile) => bundleFile.targetType === EUnityBuildTarget.V.WEBGL));
};

export const addDays = (date, days) => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

export const ordered = (arr) =>
  arr.sort((a, b) => {
    if (a.order > b.order) return 1;
    if (a.order < b.order) return -1;
    return 0;
  });
