const palette = {
  primary: {
    main: "#2329D2",
  },
  secondary: {
    main: "#3472cf",
  },
  primaryInvert: {
    backgroundColor: "#fff",
    color: "#2329D2",
  },
  disabled: {
    main: "#bdbdbd",
  },
  bg: {
    main: "white",
  },
  lightBackground: {
    backgroundColor: "#F2F2F2",
  },
  tonalOffset: 0.5,
};

export const defaultTheme = {
  fabZIndex: 1200,
  drawerWidth: "150px",
  squareButton: {},
  navColor: "black",
  navBgColor: "white",
  props: {
    MuiButtonBase: {
      // The default props to change
      disableRipple: true, // No more ripple, on the whole application 💣!
    },
    MuiInputBase: {
      // disableUnderline: true
    },
  },
  palette: {
    ...palette,
  },
  // Enable typography v2: https://material-ui.com/style/typography/#migration-to-typography-v2
  typography: {
    useNextVariants: true,
    wordBreak: "break-all",
  },
  flexColumnCenter: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  flexRowCenter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  title: {
    padding: "1rem 1rem 0 1rem",
    fontSize: "1.2rem",
    fontWeight: "bold",
  },
  subtitle: {
    padding: "0.5rem 1rem 0 1rem",
    fontSize: "1rem",
    fontWeight: "bold",
  },
  description: {
    padding: "0.5rem 1rem 0 1rem",
    fontSize: "1rem",
  },
  link: {
    padding: "0.5rem 1rem 1rem 1rem",
    fontSize: "1rem",
    fontWeight: "bold",
    pointer: "cursor",
  },

  globalStyles: {
    overflowHidden: { overflow: "hidden" },
    selectItemLvl1: { margin: "0.5rem", fontWeight: "bold" },
    selectItemLvl2: { padding: "1rem" },
    largeBtn: {
      minWidth: "300px",
      width: "300px",
      margin: "auto",
      height: "48px",
      color: "rgb(255, 255, 255) !important",
      border: "none",
      boxShadow:
        "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);",
      borderRadius: "4px !important",
      display: "block",
      transition: "background-color 0.218s ease 0s, border-color 0.218s ease 0s, box-shadow 0.218s ease 0s",
      cursor: "pointer",
      fontSize: 16,
    },
    akularBlue: {
      backgroundColor: "#2329D2 !important",
      color: "white !important",
      "&:hover": {
        backgroundColor: "#2329D2 !important",
      },
      "&:disabled": {
        backgroundColor: "#c4c4c4 !important",
      },
    },
    akularBlueInvert: {
      backgroundColor: "transparent !important",
      color: "#2329D2 !important",
      boxShadow: "none !important",
      "&:disabled": {
        backgroundColor: "transparent !important",
        color: "#bdbdbd !important",
        boxShadow: "none !important",
      },
      "&:hover": {
        backgroundColor: "white !important",
        color: "#2329D2 !important",
        boxShadow:
          "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12) !important",
      },
    },
    akularBlueInvertShadow: {
      backgroundColor: "white !important",
      color: "#2329D2 !important",
    },
    akularBlueInvertBlackShadow: {
      backgroundColor: "white !important",
      color: "#000 !important",
    },
    noShadow: {
      backgroundColor: "initial !important",
      boxShadow: "none !important",
      "&:hover": {
        backgroundColor: "initial !important",
        boxShadow: "none !important",
      },
    },
    centerText: {
      textAlign: "center",
    },
    boldText: {
      fontWeight: "bold",
    },
    smallerText: {
      fontSize: ".875rem",
    },
    facebookBtn: {
      backgroundColor: "rgba(66, 103, 178, 1)",
      color: "rgb(255, 255, 255)",
      "& svg": {
        width: "48px",
        height: "48px",
        padding: "12px",
        fill: "white",
        textAlign: "center",
        borderRadius: "4px !important",
        display: "block",
        float: "left",
        backgroundColor: "rgba(66, 103, 178, 1)",
        whiteSpace: "nowrap",
      },
      "& span": {
        fontSize: "0.9rem",
        textTransform: "uppercase",
        marginLeft: "-48px",
        lineHeight: "48px",
      },
    },
    appleBtn: {
      backgroundColor: "rgb(0,0,0)",
      color: "rgb(255, 255, 255)",
      "& svg": {
        width: "48px",
        height: "48px",
        padding: "12px",
        fill: "white",
        textAlign: "center",
        borderRadius: "4px !important",
        display: "block",
        float: "left",
        backgroundColor: "rgb(0,0,0)",
        whiteSpace: "nowrap",
      },
      "& span": {
        fontSize: "0.9rem",
        textTransform: "uppercase",
        marginLeft: "-48px",
        lineHeight: "48px",
      },
    },
    ribbon: {
      width: "200px",
      padding: "16px",
      position: "absolute",
      textAlign: "center",
      color: "#f0f0f0",
      transform: "rotate(-45deg)",
      right: "-50px",
      bottom: "25px",
      backgroundColor: "#2329d2",
    },
    ribbonMobile: {
      width: "140px",
      padding: "8px",
      position: "absolute",
      textAlign: "center",
      color: "#f0f0f0",
      transform: "rotate(-45deg)",
      right: "-33px",
      bottom: "20px",
      backgroundColor: "#2329d2",
    },
    webPreviewRibbonColor: {
      backgroundColor: "#00b9c1",
    },
    morePadding: {
      padding: "2.2rem",
    },
    smallPadding: {
      padding: "1.2rem",
    },
    typoItem: {
      paddingTop: "0.5rem",
    },
    typoList: {
      "& li": {
        marginLeft: "2rem",
        padding: ".5rem 0 .5rem 0",
      },
    },
    squareIcon: {
      width: "40px !important",
      minWidth: "40px !important",
      height: "40px !important",
      minHeight: "40px !important",
    },
    squareIconFullWidth: {
      width: "auto !important",
      minWidth: "40px !important",
    },
    grayFilter: {
      filter: "grayscale(100%)",
    },
  },
};

export const defaultThemeOverrides = (theme) => {
  return {
    MuiButton: {
      root: {
        color: palette.primary.main,
      },
      text: {
        boxShadow: "none",
        "&:active": {
          boxShadow: "none",
          color: palette.primary.main,
          backgroundColor: "transparent",
        },
        "&:hover": {
          backgroundColor: "transparent",
          color: palette.primary.main,
          boxShadow:
            "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
        },
        "&:disabled": {
          backgroundColor: "transparent",
          color: palette.disabled.main,
        },
      },
    },
  };
};
