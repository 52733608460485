import { PUBLIC_MODEL_VIEW_PATH as path } from "constants/paths";
import CleanLayout from "../../layouts/CleanLayout";
import Loadable from "react-loadable";
import LoadingSpinner from "../../components/LoadingSpinner";

export default {
  path,
  component: Loadable({
    loader: () => import(/* webpackChunkName: 'PublicModelView' */ "./components/PublicModelView"),
    loading: LoadingSpinner,
  }),
  layout: CleanLayout,
};
