import React from "react";
import PropTypes from "prop-types";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/messaging";
import "firebase/storage";
import "firebase/functions";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import { Provider } from "react-redux";
import { defaultTheme, defaultThemeOverrides } from "theme";
import * as fbConfig from "config";
import ScrollMemory from "react-router-scroll-memory";
import { ConfirmProvider } from "material-ui-confirm";
import { CloudMessaging } from "../../modules/cloudMessaging/components/CloudMessaging";
import { DETACHED_AUTH_APP } from "../../constants/firebase";
import ReactGA from "react-ga";
import { LOCALHOST, LOCALHOST_URL } from "../../constants/constants";
import { isLocalhostEmulator } from "utils/utils";
import AuthStateListener from "../../components/AuthStateListener";

let theme = createTheme(defaultTheme);
theme = { ...theme, overrides: defaultThemeOverrides(theme) };

// Initialize Firebase instance
if (!firebase.apps.some((app) => app.name === "[DEFAULT]")) firebase.initializeApp(fbConfig);
// Initialize Detached auth app
if (!firebase.apps.some((app) => app.name === DETACHED_AUTH_APP)) firebase.initializeApp(fbConfig, DETACHED_AUTH_APP);
// Initialize Messaging
if (!firebase.cm) firebase.cm = new CloudMessaging(firebase);

if (isLocalhostEmulator()) {
  const { emulators } = fbConfig;

  firebase.app().database().useEmulator(LOCALHOST, Number.parseInt(emulators.database));
  firebase.app(DETACHED_AUTH_APP).database().useEmulator(LOCALHOST, Number.parseInt(emulators.database));
  firebase.app().functions().useEmulator(LOCALHOST, Number.parseInt(emulators.functions));
  firebase.auth().useEmulator(`${LOCALHOST_URL}:${emulators.auth}`);
}

export const reduxFirebase = {
  // userProfile: 'testUsers',
  enableClaims: true,
  sessions: "webSessions",
  presence: "webPresence",
  // updateProfileOnLogin: true,
  enableLogging: false, // enable/disable Firebase Database Logging
};

// Initialize Browser history
let history;
if (!history) history = createBrowserHistory();
history.listen((location) => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

function App({ routes, store }) {
  console.log(`${window.appName}, ${window.version}/${window.commit}`);
  console.log("build timestamp", window.buildTimestamp);
  window.dataLayer.push({
    event: "pageview",
  });

  return (
    <MuiThemeProvider theme={theme}>
      <ConfirmProvider>
        <Provider store={store}>
          <ReactReduxFirebaseProvider firebase={firebase} config={reduxFirebase} dispatch={store.dispatch}>
            <AuthStateListener>
              <Router history={history}>
                <ScrollMemory />
                {routes}
              </Router>
            </AuthStateListener>
          </ReactReduxFirebaseProvider>
        </Provider>
      </ConfirmProvider>
    </MuiThemeProvider>
  );
}

App.propTypes = {
  routes: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired,
};

export default App;
