import { useTheme } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import { projectId } from "../config";
import { HUBSPOT_CHATBOT_CONTAINER_ID, HUBSPOT_SCRIPT_ID, HUBSPOT_SCRIPT_SRC } from "../constants/constants";
import { isLocalhostCheck } from "utils/utils";

const isLocalhost = isLocalhostCheck();

const useChatbot = (initialChatbotVisibility) => {
  const [chatbotVisible] = useState(initialChatbotVisibility);
  const theme = useTheme();

  useEffect(() => {
    if (!isLocalhost && !["akular-devel", "akular-devel-temp"].includes(projectId) && chatbotVisible) {
      const nodeObserver = new MutationObserver((mutations, observer) => {
        const hubspotChatbotContainer = document.getElementById(HUBSPOT_CHATBOT_CONTAINER_ID);
        if (hubspotChatbotContainer?.style) {
          Object.assign(hubspotChatbotContainer.style, { zIndex: theme.fabZIndex });
          observer.disconnect();
        }
      });

      const alreadyAppended = document.getElementById(HUBSPOT_SCRIPT_ID);

      if (alreadyAppended) {
        const hubspotChatbotContainer = document.getElementById(HUBSPOT_CHATBOT_CONTAINER_ID);
        if (!hubspotChatbotContainer) {
          nodeObserver.observe(document.body, { childList: true });
          return;
        }

        if (hubspotChatbotContainer?.style?.visibility !== "visible")
          Object.assign(hubspotChatbotContainer?.style || {}, { visibility: "visible" });
      } else {
        nodeObserver.observe(document.body, { childList: true });

        const hubSpotChatbotScript = document.createElement("script");
        hubSpotChatbotScript.type = "text/javascript";
        hubSpotChatbotScript.id = HUBSPOT_SCRIPT_ID;
        hubSpotChatbotScript.async = true;
        hubSpotChatbotScript.defer = true;
        hubSpotChatbotScript.src = HUBSPOT_SCRIPT_SRC;
        document.body.appendChild(hubSpotChatbotScript);
      }
      return () => {
        if (nodeObserver) nodeObserver.disconnect();
      };
    }
  }, [chatbotVisible, theme]);

  return chatbotVisible;
};

export default useChatbot;
