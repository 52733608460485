import { Loadable } from "utils/components";
import { PASSWORD_RESET_PATH as path } from "constants/paths";
import LoadingSpinner from "components/LoadingSpinner";

export default {
  path,
  component: Loadable({
    loader: () => import(/* webpackChunkName: 'Reset' */ "./components/ResetPassword"),
    loading: LoadingSpinner,
  }),
};
