import React from "react";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./LoadingSpinner.styles";

const useStyles = makeStyles(styles);

function LoadingSpinner({ size, classes }) {
  const defaultClasses = useStyles();
  const cls = classes || defaultClasses;
  return (
    <div className={cls.root}>
      <div className={cls.progress}>
        <CircularProgress mode="indeterminate" size={size || 80} />
      </div>
    </div>
  );
}

LoadingSpinner.propTypes = {
  size: PropTypes.number,
};

export default LoadingSpinner;
