import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  fullWidth: {
    width: "100%",
  },
  logoDesktop: {
    maxHeight: "50px",
  },
  logoMobile: {
    maxHeight: "30px",
  },
  userSelectNone: {
    userSelect: "none",
  },
}));
