import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useFirebase } from "react-redux-firebase";
import { useHistory } from "react-router-dom";

import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { validateEmail } from "utils/form";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import QueueDelayForm from "./QueueDelayForm";
import { useNotifications } from "modules/notification";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import styles from "./Navbar.styles";
import ListItemText from "@material-ui/core/ListItemText";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ContentMenuItems } from "./ContentMenuItems";
import { useFirebaseApp } from "../../modules/firebaseApp";
import {
  DASHBOARD_PATH,
  MODEL_BASE_PATH,
  MODELS_USER_PATH,
  QUEUE_PATH,
  SALE_OFFERING_PATH,
  TASK_BASE_PATH,
  UPLOAD_PATH,
} from "../../constants/paths";
import { turnAroundToString } from "utils/utils";
import { ACTION } from "akr-cloud/lib/common/useCases/constants";

const useStyles = makeStyles(styles);

function MobileMenu({ activeButton, delay }) {
  const classes = useStyles();
  const theme = useTheme();
  const globalClasses = makeStyles(theme.globalStyles)();
  const [anchorEl, setMenu] = useState();
  const history = useHistory();
  const firebase = useFirebase();
  const [inviteMail, setInviteMail] = useState();
  const [inviteName, setInviteName] = useState();
  const [open, setOpen] = useState(false);
  const [timeOpen, setTimeOpen] = useState(false);
  const [currentDelay, setCurrentDelay] = useState(delay);
  const [menuOpen, setMenuOpen] = useState(false);
  const [inviting, setInviting] = useState(false);
  const fbApp = useFirebaseApp();

  const iconRef = React.createRef();

  const { showSuccess, showError } = useNotifications();

  useEffect(() => {
    setCurrentDelay(delay);
  }, [delay]);

  function closeAccountMenu() {
    setMenu(null);
  }

  const menuClick = (path) => {
    setMenuOpen(false);
    return history.push(path);
  };

  useEffect(() => {
    if (!currentDelay) {
      firebase
        .database()
        .ref("globalState/currentTurnaroundHours")
        .once("value", (snapshot) => {
          // console.log(snapshot.val())
          setCurrentDelay(turnAroundToString(snapshot.val()));
        });
    }
  }, [currentDelay, firebase]);

  const setQueueDelay = (data) => {
    // console.log(data)
    firebase
      .database()
      .ref("globalState/currentTurnaroundHours")
      .set(data.delay)
      .then(() => {
        setCurrentDelay(data.delay);
        showSuccess("Delay changed to " + turnAroundToString(data.delay));
        setOpen(false);
      });
  };

  const invite = () => {
    if (inviteMail && inviteName) {
      setInviting(true);
      if (validateEmail(inviteMail) !== "invalid") {
        const data = {
          to: [{ email: inviteMail, name: inviteName || inviteMail }],
          params: { toName: inviteName || inviteMail, fromName: fbApp.displayName },
          templateId: 9,
          actionId: ACTION.INVITE,
        };

        fbApp.cf
          .sendEmailWithTemplateId(data)
          .then(() => {
            showSuccess("Your Friend has been invited!");
            setOpen(false);
            setInviting(false);
          })
          .catch((err) => {
            // console.log(err)
          });
      }
    } else {
      showError("email and name are mandatory");
    }
  };

  if (!fbApp.isLoaded) return null;

  return (
    <>
      <Grid
        container
        direction="row"
        alignItems="center"
        alignContent="center"
        justifyContent="flex-end"
        onClick={() => setMenuOpen(true)}>
        <Grid item>
          <IconButton
            ref={iconRef}
            aria-owns={anchorEl ? "menu-appbar" : null}
            aria-haspopup="true"
            classes={{ root: classes.buttonRoot }}>
            <MenuIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Drawer anchor={"left"} open={menuOpen} onClose={() => setMenuOpen(false)} className={classes.mobileMenu}>
        <List component="nav" aria-label="mobile navigation">
          <ListItem>
            <ListItemText primary={fbApp.displayName} />
          </ListItem>
          <Divider />
          <ContentMenuItems classes={classes} />
          {fbApp.isPathAllowed(SALE_OFFERING_PATH) && (
            <ListItem
              className={activeButton === SALE_OFFERING_PATH ? classes.mobileActive : classes.clickable}
              onClick={() => menuClick(SALE_OFFERING_PATH)}>
              <ListItemText primary="AR Premium" />
            </ListItem>
          )}
          {fbApp.isPathAllowed(UPLOAD_PATH) && (
            <ListItem
              className={activeButton === UPLOAD_PATH ? classes.mobileActive : classes.clickable}
              onClick={() => menuClick(UPLOAD_PATH)}>
              <ListItemText primary="Upload" />
            </ListItem>
          )}

          {fbApp.isPathAllowed(DASHBOARD_PATH) && (
            <ListItem
              className={activeButton.includes(DASHBOARD_PATH) ? classes.mobileActive : classes.clickable}
              onClick={() => menuClick(DASHBOARD_PATH)}>
              <ListItemText primary="Dashboard" />
            </ListItem>
          )}
          {fbApp.isPathAllowed(MODELS_USER_PATH) && (
            <ListItem
              className={
                activeButton.includes(MODELS_USER_PATH) || activeButton.includes(MODEL_BASE_PATH)
                  ? classes.mobileActive
                  : classes.clickable
              }
              onClick={() => menuClick(MODELS_USER_PATH)}>
              <ListItemText primary="Models" />
            </ListItem>
          )}
          {fbApp.ui("masterAdmin", ["R"]) && (
            <ListItem
              className={
                activeButton === QUEUE_PATH || activeButton.includes(TASK_BASE_PATH)
                  ? classes.mobileActive
                  : classes.clickable
              }
              onClick={() => menuClick(QUEUE_PATH)}>
              <ListItemText primary="Queue" />
            </ListItem>
          )}
          <Divider />
          <ListItem>
            <ListItemText>&nbsp;</ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>&nbsp;</ListItemText>
          </ListItem>
        </List>
      </Drawer>
      <Dialog
        open={open}
        className={globalClasses.morePadding}
        onClose={() => setOpen(false)}
        aria-labelledby="dialog-newModel"
        maxWidth="xs"
        fullWidth>
        <DialogTitle>
          <Grid container>
            <Grid item xs={12} className={classes.invitationGrid}>
              Invite a friend to AKULAR
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} className={globalClasses.centerText}>
              <TextField
                className={classes.field}
                name="email"
                label="E-mail"
                fullWidth
                onChange={(val) => setInviteMail(val.target.value)}
              />
              <TextField
                className={classes.field}
                name="name"
                label="Friend's Name"
                fullWidth
                onChange={(val) => setInviteName(val.target.value)}
              />
            </Grid>
            <Grid item xs={12} className={classes.invitationGrid}>
              <Button
                disableFocusRipple
                variant="contained"
                className={[globalClasses.akularBlue, classes.inviteButton].join(" ")}
                onClick={() => invite()}>
                {inviting ? <CircularProgress color="inherit" size={23} /> : "Send invitation"}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <Dialog
        open={timeOpen}
        onClose={() => setTimeOpen(false)}
        aria-labelledby="dialog-newModel"
        maxWidth="sm"
        fullWidth>
        <DialogContent>
          <p>{"Current turnaround: " + turnAroundToString(currentDelay)}</p>
          <QueueDelayForm
            currentDelay={currentDelay}
            onSubmit={(data) => setQueueDelay(data)}
            onSubmitFail={(e) => console.log(e)}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}

MobileMenu.propTypes = {
  activeButton: PropTypes.string,
  delay: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default MobileMenu;
