import Loadable from "react-loadable";
import LoadingSpinner from "components/LoadingSpinner";

// Sync route definition
export default {
  path: "/account-proposals",
  component: Loadable({
    loader: () => import(/* webpackChunkName: 'AccountProposals' */ "./components/AccountProposals"),
    loading: LoadingSpinner,
  }),
};
