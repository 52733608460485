import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useFirebase } from "react-redux-firebase";
import { Link, useHistory } from "react-router-dom";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
import Divider from "@material-ui/core/Divider";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { makeStyles } from "@material-ui/core/styles";
import { validateEmail } from "utils/form";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import QueueDelayForm from "./QueueDelayForm";
import { useNotifications } from "modules/notification";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useTheme } from "@material-ui/core/styles";
import styles from "./Navbar.styles";
import { useFirebaseApp } from "../../modules/firebaseApp";
import {
  ACCOUNT_PATH,
  ADMIN_PATH,
  ORG_ADMIN_USERS_PATH,
  SEND_INVITE_PATH,
  SUPPORT_PATH,
  TUTORIALS_PATH,
} from "../../constants/paths";
import { turnAroundToString } from "utils/utils";
import { ACTION } from "akr-cloud/lib/common/useCases/constants";
import clsx from "clsx";

const useStyles = makeStyles(styles);

function AccountMenu({ profile, delay }) {
  const [currentDelay, setCurrentDelay] = useState(delay);
  const classes = useStyles();
  const theme = useTheme();
  const globalClasses = makeStyles(theme.globalStyles)();
  const [anchorEl, setMenu] = useState();
  const history = useHistory();
  const firebase = useFirebase();
  const [inviteMail, setInviteMail] = useState();
  const [inviteName, setInviteName] = useState();
  const [open, setOpen] = useState(false);
  const [timeOpen, setTimeOpen] = useState(false);
  const [inviting, setInviting] = useState(false);
  const fbApp = useFirebaseApp();

  const iconRef = React.createRef();

  const { showSuccess, showError } = useNotifications();

  useEffect(() => {
    setCurrentDelay(delay);
  }, [delay]);

  function closeAccountMenu(e) {
    setMenu(null);
  }
  function handleMenu(e) {
    setMenu(e.target);
  }
  async function handleLogout() {
    await fbApp.clearPresence();
    fbApp.auth.signOut();
  }

  const setQueueDelay = (data) => {
    // console.log(data)
    firebase
      .database()
      .ref("globalState/currentTurnaroundHours")
      .set(data.delay)
      .then(() => {
        setCurrentDelay(data.delay);
        showSuccess("Delay changed to " + turnAroundToString(data.delay));
        setOpen(false);
      });
  };

  const invite = async () => {
    if (inviteMail && inviteName) {
      setInviting(true);
      if (validateEmail(inviteMail) !== "invalid") {
        const signInMethods = await fbApp.auth.fetchSignInMethodsForEmail(inviteMail);
        if (signInMethods.length) {
          showSuccess("E-mail is already registered.");
          setInviting(false);
          return;
        }
        const data = {
          to: [{ email: inviteMail, name: inviteName || inviteMail }],
          params: { toName: inviteName || inviteMail, fromName: fbApp.displayName },
          templateId: 9,
          actionId: ACTION.INVITE,
        };
        console.log(data);

        fbApp.cf
          .sendEmailWithTemplateId(data)
          .then(() => {
            showSuccess("Your Friend has been invited!");
            setOpen(false);
            setInviting(false);
          })
          .catch((err) => {
            // console.log(err)
          });
      }
    } else {
      showError("email and name are mandatory");
    }
  };
  if (!fbApp.isLoaded) return null;
  return (
    <>
      <Grid container direction="row" alignItems="center" alignContent="center" justifyContent="flex-end">
        <Hidden smDown>
          <Grid item onClick={handleMenu}>
            <Typography className={classes.clickable}>{fbApp.displayName}</Typography>
          </Grid>
        </Hidden>
        <Grid item onClick={handleMenu}>
          <IconButton
            ref={iconRef}
            aria-owns={anchorEl ? "menu-appbar" : null}
            aria-haspopup="true"
            classes={{ root: clsx(classes.buttonRoot, classes.buttonPadding) }}>
            <AccountCircle />
          </IconButton>
        </Grid>
      </Grid>

      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={Boolean(anchorEl)}
        onClose={closeAccountMenu}>
        {fbApp.isPathAllowed(SEND_INVITE_PATH) && (
          <MenuItem
            onClick={() => {
              setOpen(true);
              setMenu(null);
            }}>
            Invite to Akular
          </MenuItem>
        )}
        {fbApp.isPathAllowed(TUTORIALS_PATH) && (
          <Link className={classes.link} onClick={() => closeAccountMenu()} to={TUTORIALS_PATH}>
            <MenuItem>Tutorials</MenuItem>
          </Link>
        )}
        {fbApp.isPathAllowed(ACCOUNT_PATH) && (
          <Link className={classes.link} onClick={() => closeAccountMenu()} to={ACCOUNT_PATH}>
            <MenuItem>Profile Settings</MenuItem>
          </Link>
        )}

        {fbApp.ui("menuOrgAdmin", ["R"]) && fbApp.isPathAllowed(ORG_ADMIN_USERS_PATH) && (
          <Link className={classes.link} onClick={() => closeAccountMenu()} to={ORG_ADMIN_USERS_PATH}>
            <MenuItem>Organization</MenuItem>
          </Link>
        )}
        {fbApp.isPathAllowed(SUPPORT_PATH) && (
          <Link className={classes.link} onClick={() => closeAccountMenu()} to={SUPPORT_PATH}>
            <MenuItem>Contact Support</MenuItem>
          </Link>
        )}
        {fbApp.ui("masterAdmin", ["R"]) && (
          <MenuItem
            onClick={() => {
              setTimeOpen(true);
              setMenu(null);
            }}>
            Turnaround Time
          </MenuItem>
        )}
        {fbApp.ui("masterAdmin", ["R"]) && (
          <Link className={classes.link} onClick={() => closeAccountMenu()} to={ADMIN_PATH}>
            <MenuItem>Admin</MenuItem>
          </Link>
        )}
        <Divider />
        <MenuItem onClick={handleLogout}>Sign Out</MenuItem>
      </Menu>

      <Dialog
        open={open}
        className={globalClasses.morePadding}
        onClose={() => setOpen(false)}
        aria-labelledby="dialog-newModel"
        maxWidth="xs"
        fullWidth>
        <DialogTitle>
          <Grid container>
            <Grid item xs={12} className={classes.invitationGrid}>
              Invite a friend to AKULAR
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} justifyContent="center" direction="row">
            <Grid item xs={12} className={globalClasses.centerText}>
              <TextField
                className={classes.field}
                name="email"
                label="E-mail"
                fullWidth
                onChange={(val) => setInviteMail(val.target.value)}
              />
              <TextField
                className={classes.field}
                name="name"
                label="Friend's Name"
                fullWidth
                onChange={(val) => setInviteName(val.target.value)}
              />
            </Grid>
            <Grid item xs={12} className={classes.invitationGrid}>
              <Button
                disableFocusRipple
                disabled={inviting}
                variant="contained"
                className={[globalClasses.akularBlue, classes.inviteButton].join(" ")}
                onClick={() => invite()}>
                {inviting ? <CircularProgress size={25} /> : "Send invitation"}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <Dialog
        open={timeOpen}
        onClose={() => setTimeOpen(false)}
        aria-labelledby="dialog-newModel"
        maxWidth="sm"
        fullWidth>
        <DialogContent>
          <p>{"Current turnaround: " + turnAroundToString(currentDelay)}</p>
          <QueueDelayForm
            currentDelay={currentDelay}
            onSubmit={(data) => setQueueDelay(data)}
            onSubmitFail={(e) => console.log(e)}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}

AccountMenu.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired, // from enhancer (withRouter)
  }),
  firebase: PropTypes.shape({
    logout: PropTypes.func.isRequired, // from enhancer (withFirebase)
  }),
};

export default AccountMenu;
