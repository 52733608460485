import { SUCCESS_SHOW, SUCCESS_DISMISS } from "./actionTypes";

const initialState = {
  show: false,
};

export default function successfulSubmit(state = initialState, action) {
  switch (action.type) {
    case SUCCESS_SHOW:
      return { show: true };
    case SUCCESS_DISMISS:
      return { show: false };
    default:
      return state;
  }
}
