import React from "react";
import { Dialog, Grid } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import video from "../../../static/successfulSubmitV2.mp4";
import useSubmitSuccess from "../hooks/useSubmitSuccess";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";

const useStyles = makeStyles(() => ({
  exit: {
    position: "absolute",
    top: 0,
    zIndex: 2000,
  },
  video: {
    width: "100%",
    height: "auto",
  },
}));

const SuccessfulSubmitDialog = () => {
  const classes = useStyles();
  const { hideSubmitSuccess } = useSubmitSuccess();
  const { show } = useSelector((state) => state.successfulSubmit);

  return (
    <Dialog fullWidth maxWidth="lg" onClose={hideSubmitSuccess} open={show}>
      <Hidden smDown>
        <Grid className={classes.exit} container item justifyContent="flex-end">
          <IconButton onClick={hideSubmitSuccess}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </Hidden>
      <video autoPlay className={classes.video} src={video} onEnded={hideSubmitSuccess} />
    </Dialog>
  );
};

export default SuccessfulSubmitDialog;
