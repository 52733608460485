export const USER_FORM_NAME = "user";
export const LOGIN_FORM_NAME = "login";
export const SIGNUP_FORM_NAME = "signup";
export const NEW_PROJECT_FORM = "newProject";
export const NEW_USER_FORM_NAME = "newUser";
export const EDIT_PROJECT_FORM_NAME = "editProject";
export const NEW_MODEL_FORM = "newModel";
export const REVIEW_MODEL_FORM = "reviewModel";
export const PROCESS_REVIEW_MODEL_FORM = "processReviewModel";
export const DIAGNOSTIC_MODEL_FORM = "diagnosticForm";
export const QUEUE_DELAY_FORM = "queueDelay";
export const SUPPORT_TICKET_FORM = "supportTicket";
export const TUTORIAL_FORM_NAME = "tutorial";
export const SALES_OFFERING_FORM_NAME = "salesOffering";
export const RESET_PASSWORD_FORM_NAME = "resetPassword";
