import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  fabContainer: {
    zIndex: theme.fabZIndex,
  },
  storeFab: {
    width: 60,
    height: 60,
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 6px, rgba(0, 0, 0, 0.2) 0px 2px 24px",
    "&:hover": {
      boxShadow: "rgba(0, 0, 0, 0.2) 0px 2px 10px, rgba(0, 0, 0, 0.3) 0px 4px 28px",
    },
    "&:active": {
      boxShadow: "rgba(0, 0, 0, 0.2) 0px 2px 10px, rgba(0, 0, 0, 0.3) 0px 4px 28px",
    },
    zIndex: theme.fabZIndex,
  },
  floatStore: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(4),
    [theme.breakpoints.only("xs")]: {
      right: theme.spacing(2),
    },
  },
  floatStoreLeftShift: {
    right: 100,
  },
}));
