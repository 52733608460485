import React, { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AccountMenu from "./AccountMenu";
import MobileMenu from "./MobileMenu";
import styles from "./Navbar.styles";
import { Link } from "react-router-dom";
import Hidden from "@material-ui/core/Hidden";
import { useFirebase } from "react-redux-firebase";
import { Typography } from "@material-ui/core";
import { useFirebaseApp } from "../../modules/firebaseApp";
import { get } from "lodash";
import {
  ACCOUNT_PATH,
  DASHBOARD_PATH,
  MODEL_BASE_PATH,
  MODELS_BASE_PATH,
  MODELS_USER_PATH,
  PASSWORD_RESET_PATH,
  QUEUE_PATH,
  SALE_OFFERING_PATH,
  TASK_BASE_PATH,
  TUTORIALS_PATH,
  UPLOAD_PATH,
  LOGIN_PATH,
  CREATE_PASSWORD_PATH,
  ORG_ADMIN_SETTINGS_PATH,
  ORG_ADMIN_ROLES_PATH,
  ORG_ADMIN_USER_ADD_PATH,
  ORG_ADMIN_USER_DETAIL_PATH,
  ORG_ADMIN_USER_EDIT_PATH,
  ORG_ADMIN_USERS_PATH,
  ORG_ADMIN_UNIT_EDIT_PATH,
  ORG_ADMIN_UNITS_PATH,
} from "../../constants/paths";
import Box from "@material-ui/core/Box";
import { handle } from "utils/utils";
import Logo from "../../components/Logo";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles(styles);

const FORBIDDEN_PATHS = [PASSWORD_RESET_PATH, CREATE_PASSWORD_PATH];

function Navbar() {
  const classes = useStyles();
  const firebase = useFirebase();
  // const state = useSelector(state => {return state})
  // firebase.functions().useFunctionsEmulator('http://localhost:5001')
  const { pathname } = useLocation();
  const [currentDelay, setCurrentDelay] = useState(null);
  const [activeButton, setActiveButton] = useState(pathname);
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));

  const [cloudMessaging, setCloudMessaging] = useState(null);
  const fbApp = useFirebaseApp();

  const registerPushListener = (pushNotification) =>
    navigator.serviceWorker.addEventListener("message", ({ data }) => {
      const msg1 = get(data, ["data", "message"], null);
      const msg2 = get(data, ["firebase-messaging-msg-data", "data", "message"], "");

      navigator.serviceWorker.addEventListener("message", ({ data }) => pushNotification(msg1 || msg2));
    });

  const adjustScrollComponent = useCallback(() => {
    const scrollSelector = { ...document.querySelectorAll(`[data-component="scrollContainer"]`) };
    const scrollComponent = scrollSelector[0];

    if (scrollComponent) {
      switch (pathname) {
        case UPLOAD_PATH:
          scrollComponent.style.paddingBottom = smDown ? "92px" : "0";
          break;
        case QUEUE_PATH:
          scrollComponent.style.paddingBottom = "0";
          break;
        default:
          scrollComponent.style.paddingBottom = smDown ? "92px" : "32px";
      }
    }
  }, [smDown, pathname]);

  useEffect(() => {
    setActiveButton(pathname);
    adjustScrollComponent();
  }, [adjustScrollComponent, pathname]);

  useEffect(() => {
    (async () => {
      if (fbApp.isLoaded) {
        const [err, snapshot] = await handle(fbApp.db.ref(fbApp.rootRefs.currentTurnaroundHours).once("value"));
        if (!err) setCurrentDelay(snapshot.val());
      }
    })();
  }, [fbApp]);
  useEffect(() => {
    (async function () {
      if (fbApp.isLoaded && firebase && firebase.cm.isSupported && !firebase.cm.isInitialized) {
        try {
          await firebase.cm.init(fbApp.claims.userId);
          setCloudMessaging(firebase.cm);

          registerPushListener();
        } catch (e) {
          console.log(e);
        }
      }
    })();
  }, [fbApp, cloudMessaging, firebase]);

  return (
    <AppBar position="static" className={classes.appBar}>
      <Toolbar>
        <Grid container>
          <Grid item xs={4} md={2} className={classes.logo}>
            {!FORBIDDEN_PATHS.includes(activeButton) ? (
              <Link
                className={classes.link}
                to={fbApp.isLoaded ? fbApp?.orgConfig?.value?.redirects?.base ?? DASHBOARD_PATH : LOGIN_PATH}>
                <Logo />
              </Link>
            ) : (
              <Logo />
            )}
          </Grid>

          {fbApp.isLoaded && (
            <Hidden mdUp>
              <Grid item xs={4} className={classes.mobileMenuItems}>
                <Typography>
                  {(activeButton === DASHBOARD_PATH || activeButton === "/") && "Dashboard"}
                  {activeButton === UPLOAD_PATH && "Upload"}
                  {activeButton.includes(MODEL_BASE_PATH) && "Models"}
                  {activeButton === QUEUE_PATH && "Queue"}
                  {activeButton === ACCOUNT_PATH && "Settings"}
                  {activeButton === SALE_OFFERING_PATH && "AR Premium"}
                  {activeButton === TUTORIALS_PATH && "Tutorials"}
                  {activeButton.includes(ORG_ADMIN_UNITS_PATH) && "Groups"}
                  {activeButton.includes(ORG_ADMIN_UNIT_EDIT_PATH) && "Group Edit"}
                  {activeButton.includes(ORG_ADMIN_USERS_PATH) && "Users"}
                  {activeButton.includes(ORG_ADMIN_USER_EDIT_PATH) && "Edit User"}
                  {activeButton.includes(ORG_ADMIN_USER_DETAIL_PATH) && "User Detail"}
                  {activeButton.includes(ORG_ADMIN_USER_ADD_PATH) && "Add User"}
                  {activeButton.includes(ORG_ADMIN_ROLES_PATH) && "Roles"}
                  {activeButton.includes(ORG_ADMIN_SETTINGS_PATH) && "Settings"}
                </Typography>
              </Grid>
              <Grid item container xs={4} justifyContent={"flex-end"}>
                {!FORBIDDEN_PATHS.includes(activeButton) && (
                  <>
                    <Grid item>
                      <Box display="flex" alignContent="center" height="100%">
                        <MobileMenu delay={currentDelay} activeButton={activeButton} />
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box display="flex" alignContent="center" height="100%">
                        <AccountMenu delay={currentDelay} />
                      </Box>
                    </Grid>
                  </>
                )}
              </Grid>
            </Hidden>
          )}
          <Hidden smDown>
            <Grid item xs className={classes.appNav}>
              {fbApp.isLoaded && !FORBIDDEN_PATHS.includes(activeButton) ? (
                <>
                  {fbApp.isPathAllowed(SALE_OFFERING_PATH) && (
                    <Link
                      className={classes.link}
                      onClick={() => setActiveButton(SALE_OFFERING_PATH)}
                      to={SALE_OFFERING_PATH}>
                      <Button
                        disableFocusRipple
                        className={activeButton === SALE_OFFERING_PATH ? classes.appNavBtnActive : classes.appNavBtn}>
                        AR Premium
                      </Button>
                    </Link>
                  )}
                  {fbApp.isPathAllowed(UPLOAD_PATH) && (
                    <Link className={classes.link} onClick={() => setActiveButton(UPLOAD_PATH)} to={UPLOAD_PATH}>
                      <Button
                        disableFocusRipple
                        className={activeButton === UPLOAD_PATH ? classes.appNavBtnActive : classes.appNavBtn}>
                        Upload
                      </Button>
                    </Link>
                  )}
                  {fbApp.isPathAllowed(DASHBOARD_PATH) && (
                    <Link className={classes.link} onClick={() => setActiveButton(DASHBOARD_PATH)} to={DASHBOARD_PATH}>
                      <Button
                        disableFocusRipple
                        className={
                          activeButton === DASHBOARD_PATH || activeButton === "/"
                            ? classes.appNavBtnActive
                            : classes.appNavBtn
                        }>
                        Dashboard
                      </Button>
                    </Link>
                  )}
                  {fbApp.isPathAllowed(MODELS_USER_PATH) && (
                    <Link
                      className={classes.link}
                      onClick={() => setActiveButton(MODELS_USER_PATH)}
                      to={MODELS_USER_PATH}>
                      <Button
                        disableFocusRipple
                        className={
                          activeButton.includes(MODELS_BASE_PATH) || activeButton.includes(MODEL_BASE_PATH)
                            ? classes.appNavBtnActive
                            : classes.appNavBtn
                        }>
                        Models
                      </Button>
                    </Link>
                  )}
                  {fbApp.ui("menuGraphic", ["R"]) && (
                    <Link className={classes.link} onClick={() => setActiveButton(QUEUE_PATH)} to={QUEUE_PATH}>
                      <Button
                        disableFocusRipple
                        className={
                          activeButton === QUEUE_PATH || activeButton.includes(TASK_BASE_PATH)
                            ? classes.appNavBtnActive
                            : classes.appNavBtn
                        }>
                        Queue
                      </Button>
                    </Link>
                  )}
                </>
              ) : null}
            </Grid>
            <Grid item xs={3} container alignContent="center">
              {fbApp.isLoaded && !FORBIDDEN_PATHS.includes(activeButton) && <AccountMenu delay={currentDelay} />}
            </Grid>
          </Hidden>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;
